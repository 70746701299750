import React from 'react';
import clsx from 'clsx';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const SingleStackedChart = (props) => {
  const { label, value, percentage, colorInfo } = props;
  return (
    <>
      <div className={clsx(styles['report-item'], 'row')}>
        <div className='col-8'>
          <div className='d-flex row'>
            <div className='col-5 d-flex align-items-center'>
              <div
                className={styles['report-item__mark']}
                style={{ backgroundColor: _get(colorInfo, 'mainColor', '') }}
              ></div>
              <p className={clsx(styles['report-item__text'], 'ms-2')}>
                {label}
              </p>
            </div>
            <div className='col-3 d-flex justify-content-end'>
              <p className={styles['report-item__percentage']}>
                {percentage} %
              </p>
            </div>
            <div className='col-4 d-flex justify-content-end'>
              <p className={clsx(styles['report-item__text'], 'me-2')}>
                {value} Stops
              </p>
            </div>
          </div>
          <div
            className={clsx(styles['break-line'], 'my-2')}
            style={{
              borderColor: _get(colorInfo, 'mainColor', ''),
              opacity: 0.5,
            }}
          ></div>
        </div>
        <div className='col-4 d-flex'>
          <div
            className={clsx(styles['progress-bar-container'], 'progress w-100')}
            style={{ background: _get(colorInfo, 'color', '') }}
          >
            <div
              className='progress-bar'
              style={{
                width: `${percentage}%`,
                background: _get(colorInfo, 'mainColor', ''),
              }}
              role='progressbar'
              aria-valuemin='0'
              aria-valuemax='100'
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

SingleStackedChart.propType = {
  label: PropTypes.string,
  value: PropTypes.number,
  percentage: PropTypes.number,
  colorInfo: PropTypes.object,
};

export default SingleStackedChart;
