import Images from '../../../../../assets/images';
import { checkIsCurrentDate } from '../../../../../utils/helpers/formatDateTime';
import { calculateCurrentTime } from '../timeMilestones';
import styles from './styles.module.scss';

const ClockMarker = ({ currentTime, vanMarkerList, projectDate }) => {
  return currentTime >= "08:00" && currentTime <= "20:00" && checkIsCurrentDate(projectDate) ? (
    <div
      style={{ left: `${calculateCurrentTime(currentTime, vanMarkerList)}%` }}
      className={`${styles["clock-marker"]} position-absolute`}
    >
      <img className="mt-2" src={Images.orangeClock} alt="Clock" />
      <div className={`${styles["clock-marker__dot"]} position-absolute`}></div>
      <div
        className={`${styles["clock-marker__time"]} position-absolute`}
      >
        {currentTime}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default ClockMarker
