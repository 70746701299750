import React, {
  useState,
  useEffect,
  useCallback,
  memo,
  useContext,
} from 'react';
import {
  GoogleMap,
  MarkerClusterer,
  useJsApiLoader,
  DirectionsRenderer,
  Marker,
} from '@react-google-maps/api';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import _get from 'lodash/get';
import { withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useQuery } from '@tanstack/react-query';
import { AppStateContext } from '../../context/AppStateContext';
import axios from '../../config/api';

// Component
import Images from '../../assets/images/index';
import Loading from '../../components/Loading';
import TooltipCustom from '../../components/Tooltip/index';
import SummaryReportModal from '../../components/Modal/SummaryReport/SummaryReportModal';
import RouteListCard from './RouteListCard';
import MarkerCustom from '../../components/Marker/index';
import { cluster } from '../../assets/images/cluster';

// Helpers & Constants
import { PROJECT_FIELD, PROJECT_LABEL } from '../../utils/constants/Label';
import {
  DATE_TIME_FORMAT,
  HEIGHT_EXPAND,
  LIVE_TRACKING_FIELD,
  MARK_COLOR,
  SORT_ROUTE_BY,
} from '../../utils/constants/common';
import { getUserInfo } from '../../utils/helpers/getUserInfo';
import { getItemValueProjectInfo } from '../../utils/helpers/getProjectInfo';
import { generateRandomColor } from '../../utils/helpers/color';
import {
  addMinutesToTime,
  convertDateObjHHMM,
  formatDateTime,
} from '../../utils/helpers/formatDateTime';
import { getDirectionWithLargePoints } from '../../utils/helpers/getDirectionMap';
import { carMarker } from '../../assets/images/marker/car';
import Filter from './Filter';

import styles from './styles.module.scss';
import mapStyles from './MapStyle';
import SearchAutoComplete from '../../components/SearchAutocomplete';
import SpecimensModal from '../../components/Modal/Specimens/SpecimensModal';
import StopDetail from './StopDetail';
import moment from 'moment';

const options = {
  zoomControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  // disableDefaultUI: true // hide on action on map
  mapTypeControl: false,
  styles: mapStyles,
};

// TODO: save images to folder
// const clustererOptions = {
//   imagePath:
//     'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
// };

const InfoItem = ({ label, properties }) => {
  return (
    <div className='d-flex flex-row justify-content-between mt-2'>
      <p className={clsx(styles['tooltip-label'], 'text-nowrap')}>{label}</p>
      <p
        className={clsx(styles['tooltip-value'], 'ms-1 text-end')}
        style={{ color: properties?.color }}
      >
        {_get(properties, 'value', '')}
      </p>
    </div>
  );
};

const RouteList = () => {
  const { projectId } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const { setToastValue, setIsOpenMenu, isOpenMenu, setHideHeader } = useContext(AppStateContext);
  const [libraries] = useState(['places', 'geometry']);

  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [mapRef, setMapRef] = React.useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null); // Contain 2 pro: routeIndex and stopIndex
  //it will be overridden at onLoad
  // eslint-disable-next-line no-unused-vars
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [routes, setRoutes] = useState([]);
  const [routesOriginal, setRoutesOriginal] = useState([]);
  const [summaryModalShow, setSummaryModalShow] = useState(false);
  const [specimensModalShow, setSpecimensModalShow] = useState(false);
  const [directions, setDirections] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [sortByDepartTime, setSortByDepartTime] = useState(false);
  const [showAllRoutes, setShowAllRoutes] = useState(true);
  const [routeListNameHidden, setRouteListNameHidden] = useState([]);
  const [isLoadingDirections, setIsLoadingDirections] = useState(false);
  const [stopDetailSelected, setStopDetailSelected] = useState(null);
  const [expand, setExpand] = useState(HEIGHT_EXPAND.half);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [currentTime, setCurrentTime] = useState('');
  const [showUpcomingStops, setShowUpcomingStops] = useState(false);
  const [hiddenMarkersList, setHiddenMarkersList] = useState([])
  const [liveRoutes, setLiveRoutes] = useState([])

  const onClickMarker = (stopIndex, routeName) => {
    setSelectedMarker({ stopIndex, routeName });
    // Set data for stop detail
    setStopDetailSelected({
      fromSearchBox: false,
      data: {
        stopIndex,
        routeName,
      },
    });
  };

  useEffect(() => {
    setHideHeader(true);
    return () => {
      setHideHeader(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sorRouteBy = (routes, type) => {
    if (type === SORT_ROUTE_BY.name)
      return routes.sort((route_1, route_2) => {
        const vehicleName1 = _get(route_1, 'vehicle.name', '');
        const vehicleName2 = _get(route_2, 'vehicle.name', '');
        return vehicleName1.localeCompare(vehicleName2);
      });
    else
      return routes.sort((route_1, route_2) => {
        const departureTime1 = _get(route_1, 'data[0].visit.arrival_time', '');
        const departureTimeOriginal1 = _get(route_1, 'data[0].start_time', '');
        const departureTime2 = _get(route_2, 'data[0].visit.arrival_time', '');
        const departureTimeOriginal2 = _get(route_2, 'data[0].start_time', '');
        return (
          convertDateObjHHMM(departureTime1 || departureTimeOriginal1) -
          convertDateObjHHMM(departureTime2 || departureTimeOriginal2)
        );
      });
  };

  const {
    data: projectInfo = null,
    isLoading: isLoadingProjectInfo,
    fetchStatus: fetchStatusProjectInfo,
  } = useQuery({
    queryKey: ['projectInfo', projectId, getUserInfo(user).token],
    queryFn: async () => {
      const { data } = await axios.get('/project-detail-information', {
        headers: {
          Authorization: getUserInfo(user).token,
        },
        params: { id: projectId },
      });
      return data;
    },
    onError: (error) =>
      setToastValue({
        show: true,
        type: 'danger',
        message: _get(error, 'message', 'Something went wrong'),
      }),
    enabled: !!projectId && !!user,
    refetchInterval: 1000 * 5 * 60, // Refresh data every 5 minutes
    refetchIntervalInBackground: true,
    retry: 2,
  });

  const getStatusByIndex = (index, status = '', isLastStop) => {
    if (isLastStop) return 'destination';
    if (index === 0) return 'start';
    return status.replace('-', '')?.toLowerCase();
  };

  const {
    data: routeList = [],
    isLoading: isLoadingRouteList,
    fetchStatus: fetchStatusRouteList,
  } = useQuery({
    queryKey: ['projectRouteList', projectId, getUserInfo(user).token],
    queryFn: async () => {
      const {
        data: { routes = [] },
      } = await axios.get('/project-details-routes', {
        headers: {
          Authorization: getUserInfo(user).token,
        },
        params: { id: projectId },
      });
      const result = await Promise.all(
        sorRouteBy(
          routes,
          sortByDepartTime ? SORT_ROUTE_BY.time : SORT_ROUTE_BY.name
        ).map((route, index) => ({
          ...route,
          color: MARK_COLOR[index] || generateRandomColor(),
          routeName: _get(route, 'vehicle.name', ''),
          data: route?.solution?.visits?.map((stop, index) => ({
            ...stop,
            ...stop?.location,
            visit: stop?.visit,
            status: getStatusByIndex(
              index,
              stop?.stop_status,
              stop?.isLastStop
            ),
            vehicle: route?.vehicle,
          })),
        }))
      );
      if (isFirstLoad) {
        const failedRoutesName = result
          ?.filter(
            (item) =>
              item?.live_tracking_status === LIVE_TRACKING_FIELD.undispatched
          )
          ?.map((item) => item?.routeName);
        setRouteListNameHidden(failedRoutesName || []);
        setShowAllRoutes(failedRoutesName?.length > 0 ? false : true);
      }
      const liveRoutesName = result
        ?.filter(
          (item) =>
            item?.live_tracking_status === LIVE_TRACKING_FIELD.live
        )
        ?.map((item) => item?.routeName);
        
      setLiveRoutes(liveRoutesName || [])
      setRoutes(result);
      setRoutesOriginal(result);
      setIsFirstLoad(false);
      return result;
    },
    onError: (error) => {
      setToastValue({
        show: true,
        type: 'danger',
        message: _get(error, 'message', 'Something went wrong'),
      });
    },
    enabled: !!projectId && !!user,
    refetchInterval: 1000 * 5 * 60, // Refresh data every 5 minutes
    refetchIntervalInBackground: true,
    retry: 2,
  });

  const { data: summaryReport, isLoading: isLoadingSummaryReport = false } =
    useQuery({
      queryKey: ['summaryReport', projectId, getUserInfo(user).token],
      queryFn: async () => {
        const { data } = await axios.get('/project-view-report', {
          headers: {
            Authorization: getUserInfo(user).token,
          },
          params: { id: projectId },
        });
        return data;
      },
      onError: async (error) => {
        setToastValue({
          show: true,
          type: 'danger',
          message: _get(error, 'message', 'Something went wrong'),
        });
      },
      enabled: !!projectId && !!user,
      refetchInterval: 1000 * 5 * 60, // Refresh data every 5 minutes
      refetchIntervalInBackground: true,
      retry: 2,
    });

  const {
    data: specimensData = [],
    isLoading: isLoadingSpecimensData = false,
  } = useQuery({
    queryKey: ['specimensData', projectId, getUserInfo(user).token],
    queryFn: async () => {
      const { data } = await axios.get('/project-specimen-dashboard', {
        headers: {
          Authorization: getUserInfo(user).token,
        },
        params: { id: projectId },
      });
      return data;
    },
    onError: async (error) => {
      setToastValue({
        show: true,
        type: 'danger',
        message: _get(error, 'message', 'Something went wrong'),
      });
    },
    enabled: !!projectId && !!user,
    refetchInterval: 1000 * 5 * 60, // Refresh data every 5 minutes
    refetchIntervalInBackground: true,
    retry: 2,
  });

  const onSetSortDepartureTime = () => {
    setRoutes(
      sorRouteBy(
        routes,
        sortByDepartTime ? SORT_ROUTE_BY.name : SORT_ROUTE_BY.time
      )
    );
    setSortByDepartTime(!sortByDepartTime);
  };

  // If route has finished rather than 5 minutes will hide car marker
  // direction Part using for check if route > 25 way points
  const checkShowCurrentDriverMarker = (
    createdAt = null,
    arrivalTime,
    isFinished,
    tracking,
    index
  ) => {
    if (_get(projectInfo, 'status', '') === 'Completed') return false
    if (!createdAt || !arrivalTime) return false;
    const check = moment(createdAt);
    const arrivalSplit = arrivalTime.split(':');
    const currentDate = new Date();
    const arrivalTimeFull = new Date(
      check.year(),
      check.month(),
      check.date(),
      arrivalSplit[0],
      arrivalSplit[1],
      0
    );
    const diffMinutes = moment(currentDate).diff(
      moment(arrivalTimeFull),
      'minutes'
    );
    if (index !== tracking?.directionPart) return false;
    if (isFinished && diffMinutes > 5) return false;
    return true;
  };

  const getDataForDirections = async () => {
    const directionsInit = [];
    try {
      let hiddenMarkers = []
      for await (let { data, color, routeName, live_tracking_status } of routesOriginal) {
        let result = null
        if (showUpcomingStops) {
          if (live_tracking_status === "live") {
            const tempData = data.map((_item, index) => `${routeName}-${index}`)
            let latestVisitedIndex = data?.findLastIndex((item) => item?.visit);
            latestVisitedIndex = latestVisitedIndex !== -1 ? latestVisitedIndex : 0
            data = data.slice(latestVisitedIndex, latestVisitedIndex + 6) // route with 5 next stops since the current visit
            hiddenMarkers = [...hiddenMarkers, ...tempData.toSpliced(latestVisitedIndex, 6)]
            result = await getDirectionWithLargePoints(data);
          }
        } else {
          result = await getDirectionWithLargePoints(data);
        }
        const directionValue = _get(result, 'directions', []);
        const tracking = _get(result, 'tracking', {});
        const { createdAt, arrivalTime, isFinished } = tracking;
        directionValue.map((item, index) =>
          directionsInit.push({
            ...item,
            routeName,
            color,
            id: index,
            tracking,
            showDriverMarker: checkShowCurrentDriverMarker(
              createdAt,
              arrivalTime,
              isFinished,
              tracking,
              index
            ),
          })
        );
      }
      setHiddenMarkersList(hiddenMarkers)
      setDirections(directionsInit);
      setIsLoadingDirections(false);
    } catch (error) {
      setIsLoadingDirections(false);
      setDirections(directionsInit);
      setToastValue({
        show: true,
        type: 'danger',
        message: 'Get directions failed. Try refreshing page.',
      });
    }
  };

  // Auto zoom for multiple points
  const handleOnLoad = useCallback(
    async (map) => {
      setMapRef(map);
      if (routesOriginal.length === 0) return;
      // Type definitions for non-npm package Google Maps JavaScript API 3.50
      let bounds = new window.google.maps.LatLngBounds();
      getAllCoordinatesOfRoute(routesOriginal[0]?.data)?.forEach((location) =>
        bounds.extend({ lat: Number(location.lat), lng: Number(location.lng) })
      );
      map.fitBounds(bounds); // resize based on markers, native
    },
    [routesOriginal]
  );

  const getGoogleClusterInlineSvg = (color) => {
    const encoded = window.btoa(cluster(color));
    return `data:image/svg+xml;base64,${encoded}`;
  };

  const getAllCoordinatesOfRoute = (route = []) =>
    route?.filter((item) => item?.lat && item?.lng);

  const onSetShowRouteList = () => {
    const newShowAllRoutesValue = !showAllRoutes
    setShowAllRoutes(newShowAllRoutesValue);
    // Show all routes
    if (newShowAllRoutesValue) {
      setRouteListNameHidden([]);
      return;
    }

    // Hide all routes
    setRouteListNameHidden(
      routes?.map((route) => _get(route, 'routeName', ''))
    );
  };

  const handleShowUpcomingStops = () => {
    const newShowUpcomingStopsValue = !showUpcomingStops
    setShowUpcomingStops(newShowUpcomingStopsValue);
  };

  const onHideSingleRoute = (routeName) => {
    if (!routeName) return
    const hiddenListClone = [...routeListNameHidden];
    const index = hiddenListClone.indexOf(routeName);
    if (index === -1) hiddenListClone.push(routeName);
    else hiddenListClone.splice(index, 1);
    // Check if hidden all route
    if (hiddenListClone?.length === 0) setShowAllRoutes(true);
    if (hiddenListClone?.length > 0) setShowAllRoutes(false);
    setRouteListNameHidden(hiddenListClone);
  };

  const checkShowByRouteName = (name = '') =>
    !routeListNameHidden.includes(name);

  const getHeightOfMap = () => {
    if (expand === HEIGHT_EXPAND.none) return `calc(100vh - 120px)`; // 120 = 60 of main header, 60 of route header
    if (expand === HEIGHT_EXPAND.half) return `calc(50vh - 60px)`; // 60 of main header
    if (expand === HEIGHT_EXPAND.full) return '0px';
  };

  const getHeightOfRouteList = () => {
    if (expand === HEIGHT_EXPAND.none) return '60px';
    if (expand === HEIGHT_EXPAND.half) return '50vh';
    if (expand === HEIGHT_EXPAND.full) return 'calc(100vh - 60px)'; // 60 of route header
  };

  const getHeightOfSearch = () => {
    if (expand === HEIGHT_EXPAND.none) return 'calc(100vh - 163px)';
    if (expand === HEIGHT_EXPAND.half) return 'calc(50vh - 103px)';
    if (expand === HEIGHT_EXPAND.full) return 'calc(100vh - 165px)'; // 60 main header, 60 route header, 40 search box, 8 for margin padding
  };

  const onSetExpand = (type) => {
    if (type === 'Expand' && expand < 2) {
      setExpand(expand + 1);
    } else if (type === 'Collapse' && expand > 0) {
      setExpand(expand - 1);
    }
  };

  const getDataOfStopDetail = () => {
    const routeName = _get(stopDetailSelected, 'data.routeName', '');
    const index = routes?.findIndex((route) => route?.routeName === routeName);
    return {
      ...routes[index]?.data[stopDetailSelected?.data?.stopIndex],
      routeId: routes[index]?._id,
    };
  };

  const centerToMarkerByStop = (routeName, stopIndex) => {
    const checkRouteHidden = routeListNameHidden?.includes(routeName);
    const isHiddenMarker = hiddenMarkersList.includes(`${routeName}-${stopIndex}`)
    if (showUpcomingStops && !liveRoutes.includes(routeName)) return;
    if (!routeName || stopIndex === null || checkRouteHidden || isHiddenMarker) return;
    const index = routesOriginal?.findIndex(
      (route) => route?.routeName === routeName
    );
    const focusedMarker = routesOriginal[index]?.data[stopIndex];
    if (!focusedMarker?.lat || !focusedMarker?.lng) return;
    // Auto center to marker
    if (mapRef?.getZoom() < 13) mapRef?.setZoom(13);
    mapRef?.panTo({ lat: focusedMarker?.lat, lng: focusedMarker?.lng });
  };

  const updateCurrentTime = () => {
    if (!currentTime) return
    return setInterval(() => {
      // Update current time every 2 minutes
      const newTime = addMinutesToTime(currentTime, 2);
      setCurrentTime(newTime);
    }, 1000 * 60 * 2); // 2 minutes in milliseconds
  }

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      // navbar.style.display = 'none';
      // document.querySelector('.liaison-container').style.paddingTop = 0;
    }
    return () => {
      // navbar.style.display = 'flex';
      // document.querySelector('.liaison-container').style.paddingTop = '100px';
    };
  }, []);

  // Auto center based on multiple location points
  useEffect(() => {
    if (routesOriginal?.length === 0) return;
    setIsLoadingDirections(true);
    getDataForDirections();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesOriginal, showUpcomingStops]);

  // Behavior when click on stop per route
  useEffect(() => {
    if (!selectedMarker || !mapRef) return;
    const { stopIndex, routeName } = selectedMarker;
    centerToMarkerByStop(routeName, stopIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarker, mapRef]);

  useEffect(() => {
    setCurrentTime(_get(specimensData, "currentTime", ""));
  }, [specimensData])
  
  useEffect(() => {
    const interval = updateCurrentTime()
    return () => {
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime])

  if (
    !isLoaded ||
    (isLoadingProjectInfo && fetchStatusProjectInfo !== 'idle') ||
    (isLoadingRouteList && fetchStatusRouteList !== 'idle')
  )
    return <Loading />;

  return (
    <div className={styles['container']}>
      
      <div
        className={clsx(styles['stop-detail'], 'position-absolute')}
        id='stop-detail-popup'
        style={{
          display: stopDetailSelected ? 'block' : 'none',
          height: getHeightOfSearch(),
          top: expand === HEIGHT_EXPAND.full ? 166 : 103,
        }}
      >
        <StopDetail
          setStopDetailSelected={setStopDetailSelected}
          data={getDataOfStopDetail()}
          fromSearchBox={_get(stopDetailSelected, 'fromSearchBox', false)}
          expand={expand}
          projectId={projectId}
          setSelectedMarker={setSelectedMarker}
        />
      </div>
      <div
        className={clsx(
          styles['header'],
          'd-flex align-items-center justify-content-between px-4'
        )}
      >
        <div className='d-flex align-items-center'>
          <img src={Images.Hamburger} style={{ cursor: 'pointer' }} alt="" onClick={() => setIsOpenMenu(!isOpenMenu)} />
          <img
            src={Images.iconBack}
            onClick={() => {
              setHideHeader(false);
              navigate('/')}
            }
            alt='back'
            className='me-2'
            role='button'
            style={{
              marginLeft: '15px'
            }}
          />
          <p
            className={styles['header__title']}
            title={`${_get(projectInfo, 'name', '')}`}
          >
            {_get(projectInfo, 'name', '')}
          </p>
          <TooltipCustom
            placement='bottom'
            icon={Images.iconInfo}
            className={clsx(styles['tooltip-container'])}
          >
            <div className='d-flex flex-column w-100 justify-content-center'>
              <p className={clsx(styles['tooltip-title'], 'align-self-start')}>
                Project Information
              </p>
              <div className='mt-2'>
                {[
                  PROJECT_FIELD.name,
                  PROJECT_FIELD.date,
                  PROJECT_FIELD.totalStop,
                  PROJECT_FIELD.status,
                ].map((item) => (
                  <InfoItem
                    key={item}
                    label={PROJECT_LABEL[item]}
                    properties={getItemValueProjectInfo(projectInfo, item)}
                  />
                ))}
              </div>
            </div>
          </TooltipCustom>
        </div>

        <div className='d-flex'>
          <Button
            variant='light'
            className={clsx(
              styles['custom-btn'],
              'd-flex align-items-center border-0'
            )}
            onClick={() => setSummaryModalShow(true)}
            title='Project Summary Report'
          >
            <img src={Images.iconReport} alt='Collapse-expand' />
            <p className='ps-2'>Summary Report</p>
          </Button>
          <Button
            variant='light'
            onClick={() => setSpecimensModalShow(true)}
            className='d-flex align-items-center border-0 custom-btn'
          >
            <img src={Images.iconDashboard} alt='Filter' />
            <p className='ps-2'>Arrivals Dashboard</p>
          </Button>
        </div>
      </div>
      <div className='position-relative'>
        <GoogleMap
          mapContainerStyle={{
            height: getHeightOfMap(),
          }}
          mapContainerClassName={styles['map-container']}
          center={center}
          zoom={19}
          options={options}
          id='route-map'
          onLoad={handleOnLoad}
        >
          {(routeListNameHidden?.length !== routes?.length) &&
            !isLoadingDirections &&
            routesOriginal?.map(({ data, color, routeName, live_tracking_status }, routeIndex) => {
              let latestVisitedIndex = null
              if (showUpcomingStops && live_tracking_status === "live") {
                latestVisitedIndex = data?.findLastIndex((item) => item?.visit);
                latestVisitedIndex = latestVisitedIndex !== -1 ? latestVisitedIndex : 0
              }
              return (
                <div key={routeName}>
                  {checkShowByRouteName(routeName) && (!showUpcomingStops || liveRoutes.includes(routeName)) && (
                    <MarkerClusterer
                      maxZoom={12}
                      options={{
                        styles: [
                          {
                            width: 40,
                            height: 40,
                            url: getGoogleClusterInlineSvg(color),
                            textColor: '#fff',
                            className: 'cluster-map',
                          },
                        ],
                      }}
                    >
                      {(clusterer) =>
                        data?.map((stop, stopIndex) => {
                          return (
                            (latestVisitedIndex === null ||
                            (stopIndex >= latestVisitedIndex && stopIndex <= latestVisitedIndex + 5)) && ( // Markers with 5 next stops since the current visit
                              <MarkerCustom
                                key={`${stop?.id}`}
                                onClick={() => onClickMarker(stopIndex, routeName)}
                                routeName={routeName}
                                index={stopIndex}
                                routeIndex={routeIndex}
                                stop={stop}
                                clusterer={clusterer}
                                selectedMarker={selectedMarker}
                                isStartOrDestination={["start", "destination"].includes(stop?.status)}
                                mainColor={color}
                              />
                            )
                          );
                        })
                      }
                    </MarkerClusterer>
                  )}
                </div>
              );
            })}
          {(routeListNameHidden?.length !== routes?.length) &&
            !isLoadingDirections &&
            directions?.map((direction, directionIndex) => (
              <div key={`${direction?.routeName}-${direction?.id}`}>
                {checkShowByRouteName(direction?.routeName) && (
                  <div>
                    <DirectionsRenderer
                      directions={direction}
                      options={{
                        suppressMarkers: true,
                        preserveViewport: true,
                        polylineOptions: {
                          strokeOpacity: 0.6,
                          strokeColor: direction?.color,
                        },
                      }}
                    />
                    {direction?.showDriverMarker && (
                      <Marker
                        position={_get(direction, 'tracking.location', null)}
                        icon={{
                          path: carMarker,
                          scale: 1,
                          fillColor: direction?.color,
                          fillOpacity: 1,
                          strokeWeight: 1,
                          strokeColor: '#fff',
                          // rotation: _get(direction, 'tracking.heading', null),
                          // If point is last stop will set position for threshold
                          anchor: new window.google.maps.Point(20, 15),
                          scaledSize: new window.google.maps.Size(24, 24),
                        }}
                        // Show driver on top when route focus (case: multiple route has same start point)
                        zIndex={
                          selectedMarker?.routeName === direction?.routeName
                            ? 999
                            : directionIndex
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
        </GoogleMap>
        <div
          className={clsx(
            styles['panel-control'],
            'position-absolute d-flex flex-column'
          )}
        >
          <div
            className={clsx(styles['panel-control__zoom'], 'flex-column')}
            style={{ display: expand === HEIGHT_EXPAND.full ? 'none' : 'flex' }}
          >
            <Button
              variant='light'
              onClick={() => mapRef?.setZoom(mapRef?.getZoom() + 1)}
              className='align-items-center border-0'
            >
              <img src={Images.iconZoomIn} alt='zoom in' />
            </Button>
            <Button
              variant='light'
              onClick={() => mapRef?.setZoom(mapRef?.getZoom() - 1)}
              className='align-items-center border-0'
            >
              <img src={Images.iconZoomOut} alt='zoom out' />
            </Button>
          </div>
        </div>
        <SearchAutoComplete
          routeList={routes}
          setStopDetailSelected={setStopDetailSelected}
          expand={expand}
          height={getHeightOfSearch()}
          setSelectedMarker={setSelectedMarker}
          isShowingStopDetail={stopDetailSelected !== null}
        />
        {showFilter && expand === HEIGHT_EXPAND.none && (
          <Filter
            sortByDepartTime={sortByDepartTime}
            setSortByDepartTime={onSetSortDepartureTime}
            setShowAllRoutes={onSetShowRouteList}
            setShowFilter={setShowFilter}
            smallMap={expand !== HEIGHT_EXPAND.none}
            routes={routes}
            routeListNameHidden={routeListNameHidden}
            showUpcomingStops={showUpcomingStops}
            projectInfo={projectInfo}
            onHideSingleRoute={onHideSingleRoute}
            onShowUpcomingStops={handleShowUpcomingStops}
            id={1}
          />
        )}
        {/* LOADING FOR DIRECTIONS */}
        {isLoadingDirections && (
          <div
            className='spinner-border text-dark position-absolute top-50 start-50'
            role='status'
          ></div>
        )}
      </div>
      {/* ROUTE LIST */}
      <div
        className={clsx(
          styles['route-list'],
          'bottom-0 w-100 d-flex flex-column overflow-hidden'
        )}
        style={{
          height: getHeightOfRouteList(),
        }}
      >
        <div
          className={clsx(
            styles['route-list__header'],
            'd-flex position-relative align-items-center justify-content-between px-4'
          )}
        >
          <p className={styles['title-left']}>Routes ({routeList?.length})</p>
          <div className='d-flex' id='menu-button-list'>
            {sortByDepartTime && (
              <div className='d-flex align-items-center'>
                <p className={styles['sort-by']}>✓ Sorted by departure time</p>
              </div>
            )}
            <Button
              variant='light'
              onClick={() => onSetExpand('Expand')}
              className='d-flex align-items-center border-0'
              disabled={expand === HEIGHT_EXPAND.full}
            >
              <img
                src={
                  expand === HEIGHT_EXPAND.full
                    ? Images.iconExpandDisable
                    : Images.iconExpand
                }
                alt='Expand'
              />
              <p className={clsx(styles['menu-config-txt'], 'ps-2')}>Expand</p>
            </Button>
            <Button
              variant='light'
              onClick={() => onSetExpand('Collapse')}
              className='d-flex align-items-center border-0'
              disabled={expand === HEIGHT_EXPAND.none}
            >
              <img
                src={
                  expand === HEIGHT_EXPAND.none
                    ? Images.iconCollapseDisable
                    : Images.iconCollapse
                }
                alt='Collapse'
              />
              <p className={clsx(styles['menu-config-txt'], 'ps-2')}>
                Collapse
              </p>
            </Button>
            <Button
              variant='light'
              onClick={() => setShowFilter(!showFilter)}
              className='d-flex align-items-center border-0'
            >
              <img src={Images.iconFilter} alt='Filter' />
              <p className={clsx(styles['menu-config-txt'], 'ps-2')}>Filter</p>
            </Button>
          </div>
          {showFilter && expand !== HEIGHT_EXPAND.none && (
            <Filter
              sortByDepartTime={sortByDepartTime}
              setSortByDepartTime={onSetSortDepartureTime}
              setShowAllRoutes={onSetShowRouteList}
              setShowFilter={setShowFilter}
              smallMap={expand !== HEIGHT_EXPAND.none}
              routes={routes}
              routeListNameHidden={routeListNameHidden}
              showUpcomingStops={showUpcomingStops}
              projectInfo={projectInfo}
              onHideSingleRoute={onHideSingleRoute}
              onShowUpcomingStops={handleShowUpcomingStops}
              id={2}
            />
          )}
        </div>
        <RouteListCard
          showRouteList={expand !== HEIGHT_EXPAND.none}
          routeList={routes}
          onClickStop={onClickMarker}
          onHideSingleRoute={onHideSingleRoute}
          routeListNameHidden={routeListNameHidden}
          toolTipPosition={expand === HEIGHT_EXPAND.full ? 'bottom' : 'top'}
        />
      </div>
      <SummaryReportModal
        name={_get(projectInfo, 'name', '')}
        date={formatDateTime(
          _get(projectInfo, 'date', ''),
          DATE_TIME_FORMAT.DD_MMM_YYYY
        )}
        show={summaryModalShow}
        onHide={() => setSummaryModalShow(false)}
        data={summaryReport}
        loading={isLoadingSummaryReport?.toString()}
      />
      <SpecimensModal
        show={specimensModalShow}
        onHide={() => setSpecimensModalShow(false)}
        data={specimensData}
        currentTime={currentTime}
        routeList={routes}
        loading={isLoadingSpecimensData?.toString()}
        projectDate={_get(projectInfo, 'date', '')}
      />
    </div>
  );
};

export default withAuthenticator(memo(RouteList), {
  hideSignUp: true
});
