import React, { useEffect, useState } from 'react'
import clsx from 'clsx';

// Components
import ClockMarker from './ClockMarker';
import VanMarker from './VanMarker';
import VanGroupMarker from './VanGroupMarker';

import { checkIsCurrentDate } from '../../../../utils/helpers/formatDateTime';
import { timeMilestonesData, groupVanBy15Minutes, convertTimeToPosition, calculateCurrentTime } from "./timeMilestones";
import styles from './styles.module.scss'

const Timeline = ({ timelineData, currentTime, projectDate }) => {
  const [vanMarkerList, setVanMarkerList] = useState([])

  useEffect(() => {
    if (timelineData?.length > 0) {
      const groupedTimelineData = groupVanBy15Minutes(timelineData)
      const result = convertTimeToPosition(groupedTimelineData)
      setVanMarkerList(result)
    }
  }, [timelineData])

  return (
    <div className={clsx(styles['timeline-chart'])}>
      <h2 className="mb-0">Arrivals Timeline</h2>

      {/* Timeline */}
      <div className={clsx(styles['timeline-wrapper'])}>
        <div className={styles['timeline']}>

          {/* Van Marker and Van Group Marker List */}
          {
            vanMarkerList.length > 0 && (
              vanMarkerList.map((item, index) => {
                return item.vanMarkers.length === 1 ? (
                  <VanMarker key={item.groupKey} vanGroupMarker={item} />
                ) : (
                  <VanGroupMarker key={item.groupKey} vanGroupMarker={item} />
                )
              })
            )
          }

          {/* Current Time Clock */}
          <ClockMarker currentTime={currentTime} vanMarkerList={vanMarkerList} projectDate={projectDate} />

          {/* Passed Time Line */}
          <div
            style={{
              width: `${calculateCurrentTime(currentTime, vanMarkerList)}%`,
              display: currentTime >= "08:00" && currentTime <= "20:00" && checkIsCurrentDate(projectDate) ? "block" : "none",
            }}
            className={styles["passed-time"]}
          ></div>

          <div className={styles['pre-time-range']}></div>
          <div className={styles['post-time-range']}></div>
  
          {/* Timeline Range and Time Milestones */}
          <ul className={styles['milestones']}>
            {timeMilestonesData.map((timeMilestone, index) => (
              <li key={index} className={styles['milestones__item']}>
                <span style={{ left: timeMilestone.position }}>
                  {index % 2 === 0 && timeMilestone.time}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Timeline
