import React, { useEffect, useState } from 'react';
import { withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import Loading from '../components/Loading';
import { getUserInfo } from '../utils/helpers/getUserInfo';
import { useParams } from 'react-router-dom';

const QuickSightEmbedding = require('amazon-quicksight-embedding-sdk');

const embedDiv = {
  width: '100%',
  minHeight: '200vh',
  borderWidth: '0px',
  overflow: 'hidden',
  frameborder: '0',
};

const dashboardStyle = (scale) => ({
  minWidth: '790px',
  height: '88vh',
  transform: `scale(${scale})`,
  transformOrigin: 'top left',
});

const GeneralDashboard = (props) => {
  const { pageView, page } = props;
  const { user } = useAuthenticator((context) => [context.user]);
  const { token } = getUserInfo(user);
  const [scale, setScale] = useState(1);
  const params = useParams();
  const dashboardId = params?.id;

  const onDashboardLoad = () => console.log('Dashboard loaded');

  const onDashboardError = (error) => console.log('Dashboard error : ', error);

  const loadQuicksightDashboard = (dashboard) => {
    const id = 'dashboard';
    const elem = document.querySelector('.quicksight-custom');
    const clientHeight = elem.clientHeight;
    const options = {
      url: dashboard.EmbedUrl,
      container: document.getElementById(id),
      scrolling: 'no',
      height: `${clientHeight + Math.floor((clientHeight * 25)/100)}px`,
      loadingHeight: '200px',
      width: '100%',
      footerPaddingEnabled: true,
    };
    document.getElementById(id).innerHTML = '';
    const db = QuickSightEmbedding.embedDashboard(options);
    db.on('error', onDashboardError);
    db.on('load', onDashboardLoad);
  };

  const { isLoading } = useQuery(
    ['quicksightDashboardEmbedUrl', user, dashboardId],
    async () => {
      const apiName = 'MedDataApi_DynamoDB';
      const path = '/getdashboardembedurl';
      const params = {
        headers: {
          Authorization: token,
        },
        queryStringParameters: {
          dashboardId,
        },
      };
      const quicksight = await API.get(apiName, path, params);
      loadQuicksightDashboard(quicksight);
      return quicksight;
    },
    {
      enabled: !!user, // only fetch when user is defined
    }
  );

  const updateWindowDimensions = () => {
    if (window.innerWidth > 790) return;
    setScale(window.innerWidth / 790);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('.content').style.overflow = 'hidden';
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  return (
    <div
      id='dashboard'
      className={`hide-${pageView} quicksight-custom m-4`}
      style={dashboardStyle(scale || 1)}
    >
      <div>{isLoading && <Loading />}</div>
      <iframe style={embedDiv} title='dashboard' id='menuiframe' src={page} />
    </div>
  );
};

export default withAuthenticator(GeneralDashboard, {
  hideSignUp: true
});
