import React from 'react';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, Authenticator } from '@aws-amplify/ui-react';
import AppStateContextProvider from './context/AppStateContext';

const theme = {
  name: 'liaison-theme',
  // Customize here
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Authenticator.Provider>
        <AppStateContextProvider>
          <App />
        </AppStateContextProvider>
      </Authenticator.Provider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
