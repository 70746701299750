import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import CustomToggle from '../accordion/CustomToggle';
import _get from 'lodash/get';
import { getDisplayValueOfStop } from '../../utils/helpers/getProjectInfo';
import { STOP_STATUS } from '../../utils/constants/Label';
import { addMinutesToTime } from '../../utils/helpers/formatDateTime';
import styles from './styles.module.scss';

const StopRecord = ({ data, order }) => {
  const status = _get(data, 'stop_status', '');
  const { label, color } = getDisplayValueOfStop(status);
  const startTime = _get(data, 'visit.arrival_time', '');
  const time = (!startTime) || [STOP_STATUS.scheduled, STOP_STATUS.missed].includes(status) ? '' : `${startTime}`;
  const visitLog = _get(data, 'visit.log', []);
  const driverNotes =
    visitLog.length >= 1 ? visitLog[visitLog.length - 1]?.notes : '';

  const calculateArrivalTimeForFailedRoute = () => {
    if (!data?.arrival_time) return '-'
    return addMinutesToTime(data.arrival_time, _get(data, 'idle_time', 0))
  }

  return (
    <div
      className={clsx(styles['stop-record'], 'row d-flex align-items-center')}
    >
      <p
        className={clsx(styles['stop-record__text'], 'col-4')}
        title={_get(data, 'location.name', '')}
      >
        {order}. {_get(data, 'location.name', '')}
      </p>
      <div className='col-2 d-flex align-items-center p-0'>
        <div
          className={styles['stop-record__dot']}
          style={{ background: color }}
        ></div>
        <p className={clsx(styles['stop-record__time'], 'ps-1')} title={label}>
          {label}
        </p>
      </div>
      <div className='col-4 d-flex align-items-center p-0'>
        <p className={clsx(styles['stop-record__time'], 'w-50')} title={time}>
          {time ? time : '-'}
        </p>
        <p
          className={clsx(styles['stop-record__time'], 'w-50 ps-2')}
          title={_get(data, 'start_time', '') || calculateArrivalTimeForFailedRoute()}
        >
          {_get(data, 'start_time', '') || calculateArrivalTimeForFailedRoute()}
        </p>
      </div>
      <div className='col-2 p-0'>
        <p className={styles['stop-record__text']} title={driverNotes}>
          {driverNotes}
        </p>
      </div>
    </div>
  );
};

const RecordHeader = () => {
  return <div className={`${clsx(styles['stop-record'], 'row d-flex align-items-center')} ${clsx(styles['stop-record__header'])}`}>
    <span className={clsx(styles['stop-record__text'], 'col-4 fw-bold px-0')}>Stop Name</span>
    <span className={clsx(styles['stop-record__text'], 'col-2 fw-bold px-0')}>Status</span>
    <span className={clsx(styles['stop-record__text'], 'col-2 fw-bold px-0')}>Actual</span>
    <span className={clsx(styles['stop-record__text'], 'col-2 fw-bold px-0')}>Scheduled</span>
    <span className={clsx(styles['stop-record__text'], 'col-2 fw-bold px-0')}>Driver Notes</span>
  </div>
}

const ReportDetails = ({ data }) => {
  const [openKey, setOpenKey] = useState(null);
  const reportDetail = data?.details?.sort((route_1, route_2) => {
    const route1 = _get(route_1, 'stop_name', '');
    const route2 = _get(route_2, 'stop_name', '');
    return route1.localeCompare(route2);
  });
  const toggleRef = useRef(null)

  const setOpenKeyValue = (value) => {setOpenKey(value)}
  const scrollToId = (id) => {
    const listRef = toggleRef.current;
      const detailTab = listRef ? listRef.querySelector(`#${id}`) : null;
      if (detailTab) setTimeout(() => detailTab.scrollIntoView({ behavior: 'smooth' }), 500);
  }

  return (
    <div className={styles['report-detail']}>
      <p className={clsx(styles['section-title'], 'px-3')}>Report Details</p>
      <div className={styles['report-detail__content']} ref={toggleRef}>
        {reportDetail?.map((item) => (
          <Accordion key={item.stop_name} className={styles['accordion']}>
            <Card className={styles['card']}>
              <Card.Header
                className={clsx(
                  styles['card-header'],
                  'd-flex align-items-center'
                )}
                id={item.stop_name.replaceAll(' ','')}
                onClick={() => scrollToId(item.stop_name.replaceAll(' ',''))}
              >
                <CustomToggle
                  eventKey={item.stop_name}
                  data={item}
                  setOpenKeyValue={setOpenKeyValue}
                  openKey={openKey}
                />
              </Card.Header>
              <Accordion.Collapse eventKey={item.stop_name}>
                <Card.Body className={clsx(styles['card-body'], 'p-0')}>
                  <RecordHeader />
                  {item?.route_info?.map((item, index) => {
                    return (
                      <StopRecord key={index} data={item} order={index + 1} />
                    );
                  })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

ReportDetails.propType = {
  data: PropTypes.object,
};

export default ReportDetails;
