import React from 'react';
import Table from 'react-bootstrap/Table';
import { API } from 'aws-amplify';
import Loading from '../components/Loading';
import BackButton from '../components/BackButton';
import _get from 'lodash/get';
import { useQuery } from '@tanstack/react-query';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { getUserInfo } from '../utils/helpers/getUserInfo';

const RoutificProjRoute = (props) => {
  const projectId = _get(props.location.state, 'id', '');
  const routeData1 = _get(props.location.state, 'route', []);
  const { user } = useAuthenticator((context) => [context.user]);
  const { token } = getUserInfo(user);

  const {
    data: routeData = routeData1?.length ? routeData1[0]?.solution?.visits : [],
    isLoading,
  } = useQuery({
    queryKey: ['routeData_proRoute', projectId, token],
    queryFn: async () => {
      const apiName = 'Routific_API';
      const path = '/getupdatedroute?id=' + projectId;
      const params = {
        headers: {
          Authorization: token,
        },
      };
      const response = await API.get(apiName, path, params);
      const projectRouteData = response;
      if (projectRouteData.length > 0)
        return projectRouteData[0]?.solution?.visits;
    },
    enabled: !!projectId && !!token,
  });

  return (
    <div className='container'>
      <h2>Visit Details</h2>
      <div style={{ float: 'right', padding: '20px 0px' }}>
        <BackButton />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {routeData?.length ? (
            <Table responsive='sm'>
              <thead>
                <tr>
                  <th>Arrival Time</th>
                  <th>Finish Time</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Idle Time</th>
                  <th>Location</th>
                </tr>
              </thead>

              <tbody>
                {routeData?.map((json, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{json.arrival_time}</td>
                      <td>{json.finish_time}</td>
                      <td>{json.start_time}</td>
                      <td>{json.end}</td>
                      <td>{json.idle_time}</td>
                      <td>
                        {json.location?.name}, {json.location?.address}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <h5>No results found</h5>
          )}
        </>
      )}
    </div>
  );
};

export default RoutificProjRoute;
