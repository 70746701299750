import React from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

// It would be nice to have this in the state, but this variable needs to be initialised before the state is declared.
let maxTime = 0;

const RouteInformation = (props) => {
  // Sort the transport data in the props by day then time.
  // sortData();
  const { data, practiceId } = props;
  // const [selectRoute, setSelectRoute] = useState(props.selectRoute);

  // const loadRoute = (json) => {
  //   setSelectRoute(json);
  // };

  return (
    <div>
      <h3>Routes</h3>
      {data.length === 0 ? (
        <h5>No results found</h5>
      ) : (
        <Table responsive='sm'>
          <thead>
            <tr>
              <th colSpan={maxTime}>Route Name</th>
              <th colSpan={maxTime}>Days of Week</th>
              <th colSpan={maxTime}>Stop Number</th>
              <th colSpan={maxTime}>Arrival Time</th>
            </tr>
          </thead>
          <tbody>
            {data.forEach((json) => {
              if (practiceId === json.Practice_Code) {
                return (
                  <tr>
                    <td>{json.Route_Name}</td>
                    <td>
                      <Link to={`/route/${json.Route_Id}`}>{json.Run}</Link>
                    </td>
                    <td>{json.Stop_Number}</td>
                    <td>{json.Arrive_Time}</td>
                  </tr>
                );
              } else if (practiceId === json.practice_code) {
                return (
                  <tr>
                    <td>{json.vist_name}</td>
                    <td>
                      <Link to={`/route/${json.id}`}>{json.days}</Link>
                    </td>
                    <td>{json.stop_no}</td>
                    <td>{json.arrive_time}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default RouteInformation;
