import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Loading from '../components/Loading';
import GoogleRouteMap from '../components/GoogleRouteMap';
import BackButton from '../components/BackButton';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

const RoutificProjDriver = (props) => {
  const projectData = _get(props?.location, 'state', {});
  const [places, setPlaces] = useState([]);
  const [centerlat, setCenterLat] = useState(0);
  const [centerlng, setCenterLng] = useState(0);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    const getDriverInfo = async () => {
      setLoadingData(true);
      if (_isEmpty(projectData)) {
        setLoadingData(false);
        return;
      }
      const projectdriverData = projectData;
      if (projectdriverData.route.length > 0) {
        for (let ij = 0; ij < projectdriverData.route.length; ij++)
          for (
            let i = 0;
            i < projectdriverData?.route[ij]?.solution?.visits.length;
            i++
          ) {
            const details = projectdriverData?.route[ij]?.solution?.visits[i];
            const name =
              projectdriverData?.route[ij]?.solution?.visits[0]?.location?.name;
            const id = projectdriverData?.route[ij]?.vehicle?.id;
            const locatn =
              projectdriverData?.route[ij]?.solution?.visits[i].location;
            let title = '';

            if (locatn != null)
              title = `Location Name : ${locatn?.name}\nDriver Name : ${name}\nAddress : ${locatn?.address}\nStart Time : ${details?.start_time}\nFinish Time : ${details?.finish_time}\nArrival Time : ${details?.arrival_time}\nExpected Arrival Time : ${details?.expected_arrival_time}\nExpected Finish Time : ${details?.expected_finish_time}\nOriginal Start Time : ${details.original_start_time}\nOriginal Finish Time : ${details.original_finish_time}
                     `;

            if (locatn != null) {
              const place = {
                id: locatn.id,
                latitude: locatn.lat,
                longitude: locatn.lng,
                address: title,
                route_name: locatn?.name,
                status: details?.status,
                vehicle_name: name,
                id_visit_vehicle: id,
              };
              setPlaces((prevPlaces) => [...prevPlaces, place]);
              setCenterLat(locatn.lat);
              setCenterLng(locatn.lng);
            }
          }
      }
      setLoadingData(false);
    };
    getDriverInfo();
  }, [projectData]);

  return (
    <div className='container'>
      <h2>Driver's Location</h2>
      <div style={{ float: 'right', padding: '20px 0px' }}>
        <BackButton />
      </div>
      {!loadingData && (places == null || places?.length === 0) ? (
        <h5>No results found</h5>
      ) : (
        <Table responsive='sm'>
          <tbody>
            <tr>
              <td>
                <div
                  className='text-center'
                  style={{ padding: 20, paddingLeft: 30 }}
                >
                  {loadingData ? (
                    <Loading />
                  ) : (
                    <GoogleRouteMap
                      places={places}
                      centerlat={centerlat}
                      centerlng={centerlng}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default RoutificProjDriver;
