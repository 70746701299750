import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss'

const SwitchButton = ({ isOn = true, handleToggle, onColor, label, id = 'react-switch-new', isDisabled = false }) => {
  const color = onColor || '#4C697F';
  return (
    <div className='d-flex align-items-center justify-content-between'>
      <p className={styles['react-switch-text']}>{label}</p>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={styles['react-switch-checkbox']}
        id={id}
        type='checkbox'
        disabled={isDisabled}
      />
      <label
        style={{ background: isOn && color }}
        className={clsx(styles['react-switch-label'], {[styles['disabled']]: isDisabled})}
        htmlFor={id}
      >
        <span className={styles[`react-switch-button`]} />
      </label>
    </div>
  );
};

export default SwitchButton;
