import React from "react";
import { MenuItem } from 'react-pro-sidebar';
import { Link } from "react-router-dom";

const CustomMenuItem = ({ itemName, imgSrc, link, selectedRoute, setSelectedRoute }) => {
  return (
    <MenuItem
      onClick={() => {
        setSelectedRoute(link)
      }}
      active={selectedRoute === link}
      icon={
        <img
          src={imgSrc}
          alt={itemName}
        />
      }
      component={<Link to={link} className="link" />}
    >
      {itemName}
    </MenuItem>
  )
}

export default CustomMenuItem;
