import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { API } from 'aws-amplify';
import Loading from './Loading';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { getUserInfo } from '../utils/helpers/getUserInfo';
import { useQuery } from '@tanstack/react-query';

const arrayUnique = (array) => {
  let foo = new Map();
  for (const tag of array) {
    foo.set(tag.Practice_Code, tag);
  }
  return [...foo.values()];
};

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (locations, value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return [];
  } else {
    const names = locations?.filter(
      (location) =>
        location.Practice_Name &&
        location.Practice_Name.toLowerCase().indexOf(inputValue) > -1
    );
    const codes = locations?.filter(
      (location) =>
        location.Practice_Code &&
        location.Practice_Code.toLowerCase().indexOf(inputValue) > -1
    );
    const postCodes = locations?.filter(
      (location) =>
        location.Postcode &&
        location.Postcode.toLowerCase().indexOf(inputValue) > -1
    );
    return arrayUnique(names?.concat(codes, postCodes));
  }
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) =>
  suggestion.Practice_Code +
  ' - ' +
  suggestion.Practice_Name +
  ' - ' +
  suggestion.Postcode;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => (
  <div>
    {suggestion.Practice_Code} - {suggestion.Practice_Name} -{' '}
    {suggestion.Postcode}
  </div>
);

const typeHeader = {
  fontSize: 20,
  textAlign: 'center',
  paddingBottom: 30,
};

const Typeahead = ({ callback }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { token } = getUserInfo(user);

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  // const [json, setJson] = useState({});

  const { data: locations, isLoading } = useQuery({
    queryKey: ['locations_typeHead', token],
    queryFn: async () => {
      const apiName = 'MedDataApi_DynamoDB';
      const path = '/practices';
      const params = {
        headers: {
          Authorization: token,
        },
      };
      return await API.get(apiName, path, params);
    },
    enabled: !!token,
  });

  const onChange = (_event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(locations, value));
    // setJson(getSuggestions(locations, value)[0]);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (_event, { suggestion }) => {
    // Pass data back to parent.
    callback(suggestion);
  };

  const inputProps = {
    placeholder: 'Enter Location',
    value,
    onChange,
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='typeahead'>
          <div style={typeHeader}>
            Please enter Practice Name, Postcode or Practice Id
          </div>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
          />
        </div>
      )}
    </>
  );
};

export default Typeahead;
