import _get from 'lodash/get';
const MAX_STATION_LIMIT = 25 - 1;
const RETRY_DELAY = 1000;
const MAX_RETRY_COUNT = 3;
let retry_count = 0;

const getDirectionWithLargePoints = async (routeOriginal = []) => {
  retry_count = 0;
  const directionsService = new window.google.maps.DirectionsService();
  // Remove stop not include location (lat, lng)
  const route = routeOriginal.filter((item) => item?.lat && item?.lng);
  // Get latest stop has visit tracking
  const visitLogIndex = route?.findLastIndex((item) => item?.visit);
  // Default value for latest stop has complete is 0
  let latestCompleteIndex = visitLogIndex !== -1 ? visitLogIndex : 0;
  // Check latest stop complete belong to part of route (25 stop per route)
  let partIndex = Math.floor(latestCompleteIndex / 25);
  // Case route length > 25
  const parts = [];

  for (let i = 0; i < route.length; i += MAX_STATION_LIMIT) {
    parts.push(route.slice(i, i + MAX_STATION_LIMIT + 1));
  }
  const directionPromises = parts.map((part) => {
    const waypoints = part.slice(1, -1).map((stop) => ({
      location: new window.google.maps.LatLng(stop?.lat, stop?.lng),
      stopover: true,
    }));
    const serviceOptions = {
      origin: part[0],
      destination: part[part.length - 1],
      travelMode: window.google.maps.TravelMode.DRIVING,
      waypoints,
    };
    // return getDirectionsRoute(directionsService, serviceOptions);
    return { directionsService, serviceOptions };
  });

  try {
    const directionResults = await Promise.all(
      directionPromises?.map((promise) =>
        getDirectionsRoute(promise?.directionsService, promise?.serviceOptions)
      )
    );
    // const directionResults = await Promise.all(
    //   directionPromises?.map((promise) =>
    //     getDirectionsRoute(promise?.directionsService, promise?.serviceOptions)
    //   )
    // );
    let point;
    const driverCurrentIndex = visitLogIndex < 0 ? 0 : visitLogIndex;
    point = new window.google.maps.LatLng(
      route[driverCurrentIndex]?.lat,
      route[driverCurrentIndex]?.lng
    );
    const createdAt = _get(route[visitLogIndex], `visit.visitCreatedAt`, '');
    const arrivalTime = _get(route[visitLogIndex], 'visit.arrival_time', '');
    return {
      directions: directionResults,
      tracking: {
        location: point,
        directionPart: partIndex,
        createdAt,
        arrivalTime,
        isFinished: driverCurrentIndex === route?.length - 1,
      },
    };
  } catch (error) {
    console.log('ERROR 2: ', error);
  }
};

const getDirectionsRoute = (directionsService, request) => {
  return new Promise((resolve, reject) => {
    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setTimeout(() => resolve(result), 1000);
      } else if (
        status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT
      ) {
        setTimeout(() => {
          retry_count++;
          console.log('OVER QUERY LIMIT', retry_count, request);
          if (retry_count <= MAX_RETRY_COUNT)
            getDirectionsRoute(directionsService, request)
              .then((data) => resolve(data))
              .catch(reject(new Error('Can not get directions')));
          else
            reject(
              new Error(`Geocoding failed after ${MAX_RETRY_COUNT} attempts`)
            );
        }, RETRY_DELAY);
      } else {
        console.log('GET DIRECTION FAILED');
        reject(`error fetching directions: ${status}`);
        return;
      }
    });
  });
};

export { getDirectionWithLargePoints };
