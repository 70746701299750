export const SolidBoxIcon = ({
  width = '1rem',
  height = '1rem',
  color = '#667085'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M13.4735 5.2136L8.34014 8.18693C8.13348 8.30693 7.87348 8.30693 7.66014 8.18693L2.52681 5.2136C2.16014 5.00027 2.06681 4.50027 2.34681 4.18693C2.54014 3.96693 2.76014 3.78693 2.99348 3.66027L6.60681 1.66027C7.38014 1.22693 8.63348 1.22693 9.40681 1.66027L13.0201 3.66027C13.2535 3.78693 13.4735 3.9736 13.6668 4.18693C13.9335 4.50027 13.8401 5.00027 13.4735 5.2136Z"/>
    <path d="M7.62064 9.42657V13.9732C7.62064 14.4799 7.1073 14.8132 6.65397 14.5932C5.28064 13.9199 2.9673 12.6599 2.9673 12.6599C2.15397 12.1999 1.4873 11.0399 1.4873 10.0866V6.64657C1.4873 6.1199 2.04064 5.78657 2.49397 6.04657L7.28731 8.82657C7.48731 8.95323 7.62064 9.1799 7.62064 9.42657Z"/>
    <path d="M8.37988 9.42657V13.9732C8.37988 14.4799 8.89322 14.8132 9.34655 14.5932C10.7199 13.9199 13.0332 12.6599 13.0332 12.6599C13.8465 12.1999 14.5132 11.0399 14.5132 10.0866V6.64657C14.5132 6.1199 13.9599 5.78657 13.5065 6.04657L8.71322 8.82657C8.51322 8.95323 8.37988 9.1799 8.37988 9.42657Z"/>
    </svg>
  )
}