import React from "react";
import { Navbar } from "react-bootstrap";
import Images from '../../../assets/images';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Header = ({ toggle, isOpen, selectedRoute }) => {
  const pathname = window.location.pathname ?? '/';
  const { user } = useAuthenticator((context) => [context.user]);
  const dashboardList = 
    localStorage.getItem('DASHBOARD_LIST_ITEM') ?
      JSON.parse(localStorage.getItem('DASHBOARD_LIST_ITEM')) :
        null;
  const filteredDashboardItem = dashboardList?.filter((item) => item.id === pathname?.split('/')?.[1])?.[0];

  return (
    <Navbar
      bg="light"
      className="navbar shadow-sm mb-1 bg-white"
      expand
      style={{ padding: '13px 24px'}}
    >
      <img src={Images.Hamburger}  style={{ cursor: 'pointer' }} alt="" onClick={toggle} />
      <span style={{ marginLeft: '15px', fontWeight: '700', fontSize: '24px' }}>
        {!user ? 'Liaison Services' : (filteredDashboardItem?.name ?? 'Transport Tracking')}
      </span>
    </Navbar>
  );
}

export default Header;