import React, { useState } from 'react';
import { API } from 'aws-amplify';
import Loading from '../components/Loading';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import BackButton from '../components/BackButton';
import { useQuery } from '@tanstack/react-query';
import _get from 'lodash/get';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { getUserInfo } from '../utils/helpers/getUserInfo';

const RoutePage = (props) => {
  const {
    match: { params },
    json,
  } = props;
  const routeId = _get(json, 'routeId', null) || _get(params, 'id', null);
  const [routeName, setRouteName] = useState('');
  const [run, setRun] = useState('');
  
  const { user } = useAuthenticator((context) => [context.user]);
  const { token, groups } = getUserInfo(user);
  const group = groups.length ? groups[0] : null;

  const getRouteData = async (token, group) => {
    const apiName = 'MedDataApi_DynamoDB';
    const path = '/routedetail';
    const params = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        id: routeId,
        group: group,
      },
    };
    const data = await API.get(apiName, path, params);
    setRouteName(data[0].Route_Name);
    setRun(data[0].Run);
    return data;
  };

  const { data: routeData = [], isLoading } = useQuery({
    queryKey: ['routeData_routePage', token, group],
    queryFn: () => getRouteData(token, group),
    enabled: !!token && !!group, // only fetch when user is defined
  });

  return (
    <div className='container'>
      {isLoading ? (
        <Loading />
      ) : (
        <div style={{ padding: 50 }}>
          <Row>
            <Col>
              <h1>{routeName}</h1>
              <h4>Run: {run}</h4>
            </Col>
            <Col>
              <BackButton />
            </Col>
          </Row>
          <table>
            <thead>
              <tr style={{ paddingTop: 20, paddingBottom: 20 }}>
                <th style={{ minWidth: 160 }}>
                  <h6>Practice Code</h6>
                </th>
                <th style={{ minWidth: 160 }}>
                  <h6>Practice Name</h6>
                </th>
                <th style={{ minWidth: 160 }}>
                  <h6>Days of Week</h6>
                </th>
                <th style={{ minWidth: 160 }}>
                  <h6>Stop Number</h6>
                </th>
                <th style={{ minWidth: 160 }}>
                  <h6>Arrival Time</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {routeData.map((json) => {
                return (
                  <tr key={json.Stop_Number}>
                    <td style={{ minWidth: 160 }}>
                      {json.Practice_Name ? (
                        <Link to={`/dashboard/${json.Practice_Code}`}>
                          {json.Practice_Code}
                        </Link>
                      ) : (
                        json.Practice_Name
                      )}
                    </td>
                    <td style={{ minWidth: 160 }}>{json.Practice_Name}</td>
                    <td style={{ minWidth: 160 }}>{json.Run}</td>
                    <td style={{ minWidth: 160 }}>{json.Stop_Number}</td>
                    <td style={{ minWidth: 160 }}>{json.Arrive_Time}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RoutePage;
