/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import Images from '../../assets/images';
import { Link, useNavigate } from "react-router-dom";
import CustomMenuItem from "./MenuItem";
import { useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { getUserInfo } from '../../utils/helpers/getUserInfo';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';

const themes = {
  light: {
    color: {
      primary: '#667085',
      secondary: '#4C697F',
      white: '#fff'
    },
    text: {
      fontSize: '16px',
    },
    sidebar: {
      backgroundColor: '#ffffff',
      color: '#607489',
      borderRight: '1px solid var(--Neutral-100, #F2F4F7)',
      boxShadow: '0px 12px 30px 0px rgba(0, 0, 0, 0.10)'
    },
    menu: {
      menuContent: '#fbfcfd',
      icon: '#0098e5',
      hover: {
        backgroundColor: '#c5e4ff',
        color: '#44596e',
      },
      disabled: {
        color: '#9fb6cf',
      },
    },
  },
};

const SideBar = ({ isOpen, toggle, selectedRoute, setSelectedRoute, setIsOpen }) => {
  // eslint-disable-next-line no-unused-vars
  const [theme, setTheme] = React.useState('light');
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { token } = getUserInfo(user);
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
    setIsOpen(false);
  };
  const handleShow = () => setShow(true);

  const removeDuplicates = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  const { data } = useQuery({
    queryKey: ['getConfigClientData', user, 'dashboard'],
    queryFn: async () => {
      const apiName = 'Routific_API';
      const path = `/get-config-client-data?email=${user.username}&type=dashboard`;
      const params = {
        headers: {
          Authorization: token,
        },
      };
      const res = await API.get(apiName, path, params);
      if (!res.length) return [];
      const newArr = [];
      res?.map((config) => {
        const parseValue = config.value ? JSON.parse(config.value) : [];
        newArr.push(...parseValue);
        return parseValue;
      });
      localStorage.setItem('DASHBOARD_LIST_ITEM', JSON.stringify(newArr));
      return newArr.length ? removeDuplicates(newArr, "id") : [];
    },
    enabled: !!user,
  });

  const onSignOut = async () => {
    try {
      await signOut();
      setSelectedRoute('/');
      navigate('/', { replace: true });
    } catch (err) {
      console.log(err);
    }
  };

  const menuItemStyles = {
    root: {
      color: '#667085',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      // whiteSpace: 'nowrap',
      [`&.${menuClasses.collapsed}`]: {
        width: '65px',
        minWidth: '65px',
      }
    },
    icon: {
      margin: '0 5px 0 0',
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    subMenuContent: () => {
      return {
        paddingLeft: !isOpen ? 'unset' : '20px !important',
        transform: !isOpen ? 'translate(60px, 132px) !important' : null,
        maxHeight: '60vh !important',
        overflow: 'auto'
      }
    },
    SubMenuExpandIcon: {
      color: '#667085',
      display: isOpen ? 'flex' : 'none',
      '&span': {
        width: '7px',
        height: '7px'
      }
    },
    button: ({ level }) => {
      let buttonStyles = {
        margin: '8px',
        borderRadius: '6px',
        height: '48px',
      }
      if (level === 0) {
        buttonStyles = {
          ...buttonStyles,
          paddingLeft: '4px',
          paddingRight: '12px',
          fontWeight: '600',
        }
      }
      if (level === 1) {
        buttonStyles = {
          ...buttonStyles,
        }
      }
      return {
        ...buttonStyles,
        [`&.${menuClasses.disabled}`]: {
          color: themes[theme].menu.disabled.color,
        },
        '&:hover': {
          backgroundColor: '#E4E9EC',
          color: '#667085',
        },
        '&:active': {
          backgroundColor: '#E4E9EC',
          color: '#667085',
        },
        [`&.${menuClasses.active}`]: {
          backgroundColor: '#E4E9EC',
          color: '#667085',
        }
      }
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <Sidebar
      collapsed={!isOpen}
      breakPoint="md"
      rootStyles={{
        color: themes[theme].sidebar.color,
        background: themes[theme].sidebar.backgroundColor,
        boxShadow: themes[theme].sidebar.boxShadow,
        borderRight: themes[theme].sidebar.borderRight,
        width: '325px',
        zIndex: '9'
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: themes[theme].sidebar.backgroundColor,
      }}>
        <div style={{
          display: 'flex',
          height: '67px',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '18px 14px',
          fontWeight: '600'
        }}>
          <div 
            onClick={() => { 
              setSelectedRoute('/')
              window.open('https://www.gp-liaison.com', '_self');
            }} 
            style={{
              display: 'flex', 
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <img 
              src={Images.SideBarLogo} 
              alt='logo' 
              style={{
                width: '32px',
                height: '32px',
                marginRight: '5px'
              }} />
            {
              isOpen ? (
                <span style={{ fontSize: '18px', fontWeight: '700', fontStyle: 'normal', whiteSpace: 'nowrap' }}>{' '} Liaison Services</span>
              ) : null
            }
          </div>
          {/* {
            <div onClick={toggle} style={{ cursor: 'pointer', visibility: isOpen ? 'visible' : 'hidden' }}>
              <img src={Images.SideBarArrowLeft} alt="SideBarArrowLeft" />
            </div>
          } */}
        </div>
        <div style={{ flex: 1, marginBottom: '32px' }}>
          <Menu menuItemStyles={menuItemStyles} closeOnClick>
            <MenuItem
              active={selectedRoute === '/'}
              onClick={() => {
                setSelectedRoute('/')
              }}
              icon={
                <img
                  src={Images.SideBarTransport}
                  alt={`SideBarTransport`}
                />
              }
              component={<Link to="/" className="link" />}
            >
              Transport Tracking
            </MenuItem>
            <SubMenu
              active={!isOpen && selectedRoute !== '/'}
              label="Dashboard" 
              icon={
                <img
                  src={Images.SideBarDashboard}
                  alt={`Dashboard`}
                />
              }
              defaultOpen
            >
              {
                data && data.map((route, index) => {
                  return (
                    <CustomMenuItem
                      key={index}
                      itemName={route.name}
                      imgSrc={Images.SideBarDashboardItem}
                      link={`/${route.id}`}
                      selectedRoute={selectedRoute}
                      setSelectedRoute={setSelectedRoute}
                    />
                  )
                })
              }
            </SubMenu>
          </Menu>
        </div>
        <div
          onClick={handleShow}
          style={{
            padding: '12px',
            display: 'flex',
            alignItems: 'center',
            height: '50px',
            cursor: 'pointer',
            marginBottom: '5px'
          }}
        >
          <img src={Images.SideBarSignout} alt="sign out" style={{ marginRight: '5px', paddingLeft: '4px' }} />
          {
            isOpen ? (
              <span style={{ fontWeight: '600', fontSize: '14px' }}>Sign out</span>
            ) : null
          }
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
        style={{
          marginTop: '60px'
        }}
      >
        <Modal.Body>
          <Modal.Title 
            className="text-center" 
            style={{ fontSize: themes[theme].text.fontSize, color: themes[theme].color.primary }}
          >Sign out</Modal.Title>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center" style={{ borderTop: 'none' }}>
          <Button size="sm" variant="light" onClick={handleClose} style={{
            color: themes[theme].color.secondary,
            background: themes[theme].color.white,
            border: `1px solid ${themes[theme].color.secondary}`
          }}>
            Cancel
          </Button>
          <Button size="sm" variant="secondary" onClick={onSignOut} style={{
            color: themes[theme].color.white,
            background: themes[theme].color.secondary,
          }}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </Sidebar>
  )
}

export default withAuthenticator(SideBar, {
  hideSignUp: true
});
