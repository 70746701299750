import { Chart } from 'react-google-charts';
import React from 'react';

const specimenStyle = {
  borderBottom: 1,
  borderBottomStyle: 'solid',
  paddingBottom: 10,
  borderColor: 'rgb(227, 225, 225)',
};

const SpecimenCount = (props) => {
  const { data } = props;
  return (
    <div className={'table-responsive-sm my-pretty-chart-container'}>
      <h3 style={specimenStyle}>Specimen Sample Data</h3>
      <Chart
        chartType='ColumnChart'
        data={data}
        width='100%'
        height='400px'
        legendToggle
      />
    </div>
  );
};

export default SpecimenCount;
