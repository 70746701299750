import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import _get from 'lodash/get';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CollectionList from '../CollectionList';

import Images from '../../../../assets/images';
import { getAverageTime } from '../../../../utils/helpers/formatDateTime';
import styles from '../styles.module.scss';
import './tooltip.styles.scss';

const DeliveryRow = ({index, hospital, onSelectedRow, expandedRow}) => {
  const deliveryRowRef = useRef(null)
  const [hasContributingVans, setHasContributingVans] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false);
  const [subTooltipShow, setSubTooltipShow] = useState(null);


  const totalCollections = hospital?.isTransferStop
    ? hospital?.totalCollectionOfTransferStop
    : hospital?.totalCollection
    ? hospital.totalCollection
    : null;

  const handleTooltipEnter = () => setShowTooltip(true);

  const handleTooltipLeave = () => 
    setShowTooltip(false);
  
  useEffect(() => {
    if (
      hospital?.collectedFrom?.length > 1 ||
      (hospital?.collectedFrom?.length === 1 &&
        hospital?.collectedFrom[0].collectedBy !== hospital?.deliveriedBy)
    ) {
      setHasContributingVans(true);
      return
    }
    setHasContributingVans(false)
  }, [hospital?.collectedFrom, hospital?.deliveriedBy])

  const handleExpand = () => {
    if (!hasContributingVans) return
    onSelectedRow(index)

    // Use setTimeout to ensure that selectedRow.scrollIntoView() is called after row is expanded
    setTimeout(() => {
      if (deliveryRowRef.current) {
        deliveryRowRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 500);
  }

  const findOldestSpecimenAge = () => {
    return hospital?.collectedFrom?.reduce((oldest, current) => {
      const oldestTime = oldest?.deliverySpecimen?.oldestSpecimenAge?.split(":") || ['00', '00'];
      const currentTime = current?.deliverySpecimen?.oldestSpecimenAge?.split(":") || ['00', '00'];
      if (
        oldestTime &&
        currentTime &&
        (oldestTime[0] > currentTime[0] ||
          (oldestTime[0] === currentTime[0] && oldestTime[1] > currentTime[1]))
      )
        return oldest;
      return current;
    });
  };

  const findYoungestSpecimenAge = () => {
    return hospital?.collectedFrom?.reduce((youngest, current) => {
      const youngestTime = youngest?.deliverySpecimen?.youngestSpecimenAge?.split(":") || ['25', '00'];
      const currentTime = current?.deliverySpecimen?.youngestSpecimenAge?.split(":") || ['25', '00'];
      if (
        youngestTime &&
        currentTime &&
        (youngestTime[0] < currentTime[0] ||
          (youngestTime[0] === currentTime[0] && youngestTime[1] < currentTime[1]))
      )
        return youngest;
      return current;
    });
  };

  const getCollectionFromCollectedFrom = (stops = []) => {
    return stops.flat(1)?.map(item => ({
      stopName: item?.stopName,
      actual: item?.arrivalTime,
      schedule: item?.scheduleTime
    }))
  }
  
  return (
    <>
      <tr
        ref={deliveryRowRef}
        className={clsx({[styles['clickable-row']]: hasContributingVans})}
        onClick={handleExpand}
      >
        <td className='ps-4 text-start'>
          <img
            style={{display: hasContributingVans ? 'inline-block' : 'none'}}
            src={Images.iconArrowDown}
            alt={`Arrow ${expandedRow === index ? 'up' : 'down'}`}
            className={clsx(expandedRow === index ? styles['arrow-up'] : styles['arrow-down'], 'me-2')}
          />
          {_get(hospital, 'deliveriedBy', '-')}
        </td>
        <td>
          {_get(hospital, 'eta', '-')}
          {!hospital?.isCompleted && (
            <img
              src={Images.iconEtaTruck}
              alt='Estimated Delivery Time'
              className='ms-2'
              title='Estimated Delivery Time'
            />
          )}
        </td>
        <td
          className={styles["total-collection-cell"]}
          onMouseEnter={handleTooltipEnter}
          onMouseLeave={handleTooltipLeave}
        >
          <OverlayTrigger
            show={showTooltip && totalCollections}
            placement="right"
            overlay={
              <Tooltip
                id="root-collection-tooltip"
                className={
                  totalCollections && !hasContributingVans
                    ? "tooltip-collection"
                    : ""
                }
                onMouseEnter={
                  totalCollections && !hasContributingVans
                    ? handleTooltipEnter
                    : undefined
                }
                onMouseLeave={
                  totalCollections && !hasContributingVans
                    ? handleTooltipLeave
                    : undefined
                }
              >
                {totalCollections && !hasContributingVans ? (
                  <CollectionList data={getCollectionFromCollectedFrom(
                    hospital?.collectedFrom?.map(item => item?.deliverySpecimen?.stops)
                  )} />
                ) : (
                  <p>Expand to view more</p>
                )}
              </Tooltip>
            }
          >
            <p>{totalCollections ? totalCollections : "-"}</p>
          </OverlayTrigger>
        </td>
        <td>
          {_get(hospital, 'deliverySpecimen.oldestSpecimenAge', '-')}
        </td>
        <td>
          {_get(hospital, 'deliverySpecimen.youngestSpecimenAge', '-')}
        </td>
        <td>
          {(hospital?.deliverySpecimen?.oldestSpecimenAge &&
            hospital?.deliverySpecimen?.youngestSpecimenAge) ?
            getAverageTime([
              hospital.deliverySpecimen.oldestSpecimenAge,
              hospital.deliverySpecimen.youngestSpecimenAge,
            ]) : '-'}
        </td>
        <td className='text-truncate pe-2 text-start' title={_get(hospital, 'deliverySpecimen.stopNamePickup', '-')}>
          {_get(hospital, 'deliverySpecimen.stopNamePickup', '-')}
        </td>
      </tr>
      {
        <div className={clsx(styles['expanded-row-content'], {[styles['expanded']]: expandedRow === index})}>
          <tr className={styles['expaneded-row-head']}>
            <td>Collected by</td>
            <td></td>
            <td>No. Collections</td>
            <td>Oldest</td>
            <td>Youngest</td>
            <td>Mean</td>
            <td>Oldest From</td>
          </tr>
          {
            hospital?.collectedFrom?.length > 0 &&
            hospital?.collectedFrom.map((collectedRow, index) => (
              <tr className={styles['expaneded-row-body']} key={index}>
                <td>{_get(collectedRow, 'collectedBy', '-')}</td>
                <td></td>
                <td
                  className={styles["total-collection-cell"]}
                  onMouseEnter={() => setSubTooltipShow(collectedRow?.visitId)}
                  onMouseLeave={() => setSubTooltipShow(null)}
                >
                  <OverlayTrigger
                    show={subTooltipShow === collectedRow?.visitId && collectedRow?.deliverySpecimen !== undefined}
                    placement="right"
                    delay={200}
                    overlay={
                      <Tooltip
                        id="sub-collection-tooltip"
                        className={"tooltip-collection"}
                        onMouseEnter={() => setSubTooltipShow(collectedRow?.visitId)}
                        onMouseLeave={() => setSubTooltipShow(null)}
                      >
                          <CollectionList
                            data={getCollectionFromCollectedFrom(
                              collectedRow?.deliverySpecimen?.stops
                            )}
                          />
                      </Tooltip>
                    }
                  >
                    <p>{collectedRow?.totalCollection ? collectedRow.totalCollection : "-"}</p>
                  </OverlayTrigger>
                </td>
                <td>
                  <span className={clsx({
                    [styles['hight-light']]: _get(collectedRow, 'deliverySpecimen.oldestSpecimenAge', '-') === findOldestSpecimenAge()?.deliverySpecimen?.oldestSpecimenAge})}
                  >
                    {_get(collectedRow, 'deliverySpecimen.oldestSpecimenAge', '-')}
                  </span>
                </td>
                <td>
                  <span className={clsx({
                    [styles['hight-light']]: _get(collectedRow, 'deliverySpecimen.youngestSpecimenAge', '-') === findYoungestSpecimenAge()?.deliverySpecimen?.youngestSpecimenAge
                  })}>{_get(collectedRow, 'deliverySpecimen.youngestSpecimenAge', '-')}</span>
                </td>
                <td>
                  {
                    (collectedRow?.deliverySpecimen?.oldestSpecimenAge &&
                    collectedRow?.deliverySpecimen?.youngestSpecimenAge) ?
                    getAverageTime([
                      collectedRow.deliverySpecimen.oldestSpecimenAge,
                      collectedRow.deliverySpecimen.youngestSpecimenAge
                    ]) : '-'
                  }
                </td>
                <td className='pe-2 text-start' title={_get(collectedRow, 'deliverySpecimen.stopNamePickup', '-')}>
                  <span className={clsx(
                    'text-truncate d-inline-block w-auto mw-100 mt-1',
                    {[styles['hight-light--long-text']]: _get(collectedRow, 'deliverySpecimen.oldestSpecimenAge', '-') === findOldestSpecimenAge()?.deliverySpecimen?.oldestSpecimenAge})}
                  >
                    {_get(collectedRow, 'deliverySpecimen.stopNamePickup', '-')}
                  </span>
                </td>
              </tr>
            ))
          }
        </div>
      }
    </>
  )
}

export default DeliveryRow