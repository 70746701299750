import React, { memo, useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import _get from 'lodash/get';
import styles from './styles.module.scss';
import clsx from 'clsx';
import Images from '../../assets/images';
import { HEIGHT_EXPAND } from '../../utils/constants/common';
import useOnClickOutside from '../../utils/helpers/clickOutside';
import { convertDateObjHHMM } from '../../utils/helpers/formatDateTime';
import NoResult from '../NoResult';

const SearchAutoComplete = ({
  routeList,
  setStopDetailSelected,
  expand,
  height,
  setSelectedMarker,
  isShowingStopDetail = false,
}) => {
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const ref = useRef();
  const [expandSearch, setExpandSearch] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const stopDetailPopupElement = document.getElementById('stop-detail-popup');
  // const menuButtonList = document.getElementById('menu-button-list');
  useOnClickOutside(
    ref,
    () => {
      if (expand === HEIGHT_EXPAND.full && !isShowingStopDetail)
        setExpandSearch(false);
    },
    [stopDetailPopupElement]
  );

  const getSuggestionValue = (value = '') => {
    const inputValue = value?.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength === 0) return [];
    const results = [];
    routeList?.forEach((route) => {
      const data = _get(route, 'data', []);
      const routeName = _get(route, 'routeName', '');
      const color = _get(route, 'color', '');
      data?.forEach((stop, stopIndex) => {
        const isLastStop = _get(stop, 'isLastStop', false)
        const stopName = _get(stop, 'name', '')?.toLowerCase();
        if (stopName.includes(inputValue) && stopIndex !== 0 && !isLastStop)
          results.push({
            routeName,
            stopIndex,
            stop,
            color,
            time: _get(stop, 'start_time', ''),
          });
      });
    });
    // Sort stop name by alphabet
    const sortData = results.sort((route1, route2) => {
      const stopName1 = _get(route1, 'stop.name', '');
      const stopName2 = _get(route2, 'stop.name', '');
      return stopName1.localeCompare(stopName2);
    });
    const groupedData = sortData.reduce((acc, obj) => {
      const { name } = obj?.stop;
      if (!acc[name]) acc[name] = [];
      acc[name].push(obj);
      return acc;
    }, {});
    // Sort the items within each group by time
    for (const stopName in groupedData) {
      groupedData[stopName].sort(
        (a, b) => convertDateObjHHMM(a?.time) - convertDateObjHHMM(b?.time)
      );
    }
    const groupedValues = Object.values(groupedData).flat(1);
    return groupedValues;
  };

  const onInputChange = (_e, { newValue }) => {
    setInput(newValue);
    // When input will hide stop detail
    setStopDetailSelected(null);
  };

  const onSuggestionSelected = (_e, { suggestion }) => {
    const routeName = _get(suggestion, 'routeName', '');
    const stopIndex = _get(suggestion, 'stopIndex', '');
    setStopDetailSelected({
      fromSearchBox: true,
      data: {
        routeName,
        stopIndex,
      },
    });
    setSelectedMarker({ routeName, stopIndex });
    setShowSuggestions(true);
  };

  const renderInputComponent = (inputProps) => (
    <>
      {expandSearch ? (
        <div
          className={clsx(
            styles['search-box-container'],
            'd-flex align-items-center'
          )}
        >
          <img style={{ width: 24 }} src={Images.iconSearch} alt='Search' />
          <input
            {...inputProps}
            id='search-input'
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
          />
          {input?.length > 0 && (
            <img
              style={{ width: 16 }}
              src={Images.iconCloseCircle}
              alt='Clear'
              onClick={() => {
                setInput('');
                setSuggestions([]);
              }}
            />
          )}
        </div>
      ) : (
        <img
          style={{ width: 24, cursor: 'pointer' }}
          src={Images.iconSearch}
          alt='Search'
          onClick={() => setExpandSearch(true)}
        />
      )}
    </>
  );

  const renderSuggestion = (suggestion) => {
    const routeName = _get(suggestion, 'routeName', '');
    const stopName = _get(suggestion, 'stop.name', '');
    const color = _get(suggestion, 'color', '');
    const startTime = _get(suggestion, 'stop.start_time', '') || _get(suggestion, 'stop.arrival_time', '');
    const title = `${stopName} - ${routeName}`;
    const address = _get(suggestion, 'stop.address', '');
    return (
      <div
        className={clsx(
          styles['result-item'],
          'd-flex flex-column justify-content-center px-3 py-3'
        )}
      >
        <div className='d-flex w-100 justify-content-between'>
          <p className={styles['result-item__txt']} title={title}>
            {stopName} - <span style={{ color: color }}>{routeName}</span>
          </p>
          <div className={clsx(styles['result-item__time_label'], 'ms-3')}>
            <p>{startTime}</p>
          </div>
        </div>
        <p className={clsx(styles['result-item__address'], 'mt-2')}>
          {address}
        </p>
      </div>
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    // If has height will show total result
    const { style } = containerProps;
    const inputValue = input?.trim()?.length;
    return (
      <div
        {...containerProps}
        className={styles['result-list']}
        style={{
          ...style,
          height: height,
          display: inputValue > 0 ? 'block' : 'none',
        }}
      >
        <div
          className={clsx(
            styles['result-list__header'],
            'd-flex justify-content-between align-items-center px-2 py-1'
          )}
        >
          <p className={styles['result-list__title']}>SEARCH RESULTS</p>
          <p className={styles['result-list__count']}>
            {suggestions?.length} results
          </p>
        </div>
        <div
          className={styles['result-list__content']}
          style={{
            height: `calc(100% - ${
              expand === HEIGHT_EXPAND.full ? '40px' : '24px'
            })`,
            background: 'white',
          }}
        >
          {children}
          {suggestions?.length === 0 && <NoResult />}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      expand !== HEIGHT_EXPAND.full ||
      isShowingStopDetail ||
      isInputFocused ||
      input?.trim()?.length > 0
    )
      return setExpandSearch(true);
    setExpandSearch(false);
  }, [expand, input, isInputFocused, isShowingStopDetail, suggestions]);

  return (
    <div
      className={clsx(styles['search-box'], 'position-absolute d-sm-block')}
      id='search-box'
      ref={ref}
      style={{
        top: expand === 2 ? '63px' : '0',
        width: expandSearch ? '352px' : '40px',
        overflow: expandSearch ? 'inherit' : 'hidden',
      }} // each header is 60px, padding 4px
    >
      <Autosuggest
        suggestions={suggestions}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={(_suggestion) => input} // set value for input
        inputProps={{
          placeholder: 'Search Stops',
          value: input,
          onChange: onInputChange,
        }}
        onSuggestionsFetchRequested={({ value }) =>
          setSuggestions(getSuggestionValue(value))
        }
        // onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsClearRequested={() => {}}
        onSuggestionSelected={onSuggestionSelected}
        shouldKeepSuggestionsOnSelect
        focusInputOnSuggestionClick={false}
        alwaysRenderSuggestions={showSuggestions}
        theme={{
          container: {
            position: 'relative',
            background: '#ffffff',
            width: '100%',
            height: '40px',
            borderRadius: '3px',
            boxShadow: `0px 4px 12px rgba(16, 24, 40, 0.08)`,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          },
          input: {
            fontSize: '14px',
            outline: 'none',
            border: 'none',
            width: '100%',
            padding: '0 12px',
            borderRadius: '4px',
          },
          inputFocused: {},
          suggestionsContainer: {
            background: '#fff',
            position: 'absolute',
            top: '42px',
            left: 0,
            width: '100%',
          },
          suggestionsContainerOpen: {
            top: '39px',
            height: height,
            zIndex: 998,
            boxShadow: `-5px 0 5px -5px rgba(0, 0, 0, 0.3)`,
          },
          suggestionsList: {
            listStyleType: 'none',
            padding: 0,
            width: '100%',
          },
          suggestion: {
            borderColor: '#EAECF0',
            borderStyle: 'solid',
            borderWidth: 0,
            borderBottomWidth: '1px',
            height: '88px',
            display: 'flex',
          },
        }}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />
    </div>
  );
};

export default memo(SearchAutoComplete);
