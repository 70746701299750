/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  QueryClientProvider,
  QueryClient,
} from '@tanstack/react-query';
import MainLayout from './components/Layout';
import SearchPage from './pages/SearchPage';
import PracticePage from './pages/PracticePage';
import RoutePage from './pages/RoutePage';
import RoutificProjRoute from './pages/RoutificProjRoute';
import RoutificProjDriver from './pages/RoutificProjDriver';
import Routific from './pages/Routific';
import GeneralDashboard from './pages/GeneralDashboard';
import RouteList from './pages/RouteList';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import './App.css';

import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsconfig);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'medapi',
        endpoint: 'https://tm6dr3b4y1.execute-api.eu-west-2.amazonaws.com',
      },
      {
        name: 'MedDataApi_DynamoDB',
        endpoint: 'https://3xxji5ygma.execute-api.eu-west-2.amazonaws.com/beta',
      },
      {
        name: 'Routific_API',
        endpoint: `${process.env.REACT_APP_API_URL}/routific-api`,
      },
      {
        name: 'quicksight',
        endpoint: 'https://vznunkyk0m.execute-api.eu-west-2.amazonaws.com',
      },
      {
        name: 'cmdashboard',
        endpoint: 'https://42b9eyfn63.execute-api.eu-west-2.amazonaws.com',
      },
    ],
  },
});

const App = () => {
  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1, // number of retries if the request fails
        refetchOnWindowFocus: false, // don't refetch when window regains focus
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {/* <Header user={user} /> */}
      <MainLayout>
        <Routes>
          <Route
            exact
            path='/'
            element={
              <Routific />
            }
          />
          <Route exact path='/dashboard' element={<SearchPage />} />
          <Route path='/dashboard/:id' element={<PracticePage />} />
          <Route path='/:id' element={<GeneralDashboard />} />
          <Route path='/projroute' element={<RoutificProjRoute />} />
          <Route path='/projdriver' element={<RoutificProjDriver />} />
          <Route path='/route/:id' element={<RoutePage />} />
          <Route path='/routing/:projectId' element={<RouteList />} />
        </Routes>
      </MainLayout>
    </QueryClientProvider>
  );
};

export default App;