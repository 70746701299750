import React, { useContext, useEffect, useRef } from 'react';
import Images from '../../../assets/images';
import clsx from 'clsx';
import _get from 'lodash/get';
import { STOP_STATUS_INFO } from '../../../utils/constants/common';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AppStateContext } from '../../../context/AppStateContext';
import { useQuery } from '@tanstack/react-query';
import { getUserInfo } from '../../../utils/helpers/getUserInfo';
import styles from './styles.module.scss';
import axios from './../../../config/api';
import { STOP_STATUS } from '../../../utils/constants/Label';
import { addMinutesToTime } from '../../../utils/helpers/formatDateTime';

const StopSection = ({ label, value }) => (
  <>
    {value && (
      <div className={clsx(styles['stop-info__section'], 'd-flex')}>
        <p className={clsx(styles['stop-info__label'], 'col-5')}>{label}</p>
        <p className={clsx(styles['stop-info__text'], 'col-7')}>{value}</p>
      </div>
    )}
  </>
);

const StopDetail = (props) => {
  const {
    data,
    setStopDetailSelected,
    fromSearchBox,
    projectId,
    setSelectedMarker,
  } = props;
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef();
  const { user } = useAuthenticator((context) => [context.user]);
  const { setToastValue } = useContext(AppStateContext);
  const contentRef = useRef(null);

  // const menuButtonList = document.getElementById('menu-button-list');

  // Call hook passing in the ref and a function to call on outside click
  // useOnClickOutside(ref, () => setStopDetailSelected(null), [menuButtonList]);

  const calculateArrivalTime = () => {
    if (!data?.arrival_time) return ''
    return addMinutesToTime(data.arrival_time, _get(data, 'idle_time', 0))
  }

  const vehicleName = _get(data, 'vehicle.name', '');
  const status = _get(data, 'stop_status', '');
  const stopName = _get(data, 'name', '');
  const signatureId = _get(data, 'visit._signature', '');
  const attachment = _get(data, 'visit.photo_url', '');
  const originalStartTime = _get(data, 'visit.original_start_time', '');
  const startTime = _get(data, 'start_time', '');
  const phoneNumber = _get(data, 'vehicle.phone-number', '');
  const email = _get(data, 'vehicle.email', '');
  const types = _get(data, 'vehicle.types', [])?.join(', ');
  const visitLog = _get(data, 'visit.log', []);
  const driverNote =
    visitLog.length >= 1 ? visitLog[visitLog.length - 1]?.notes : '';
  const stopNote = _get(data, 'notes', '');
  const practiceCode = _get(data, 'customNotes.Practice code', '');
  const visitArrivalTime = _get(data, 'visit.arrival_time', '');
  const arrivalTime = calculateArrivalTime();
  const isStart = _get(data, 'order', null) === 0;
  const isLastStop = _get(data, 'isLastStop', false);
  const address = _get(data, 'address', '');
  const shiftStart = _get(data, 'vehicle.shift-start', '');
  const shiftEnd = _get(data, 'vehicle.shift-end', '');
  const availability =
    !shiftStart && !shiftEnd ? '' : `${shiftStart} to ${shiftEnd}`;
  const routeId = _get(data, 'vehicle.routeId', '');

  const { color, background } =
    STOP_STATUS_INFO[status.replace('-', '').toLowerCase()] || {};

  const { data: signatureData } = useQuery({
    queryKey: ['summaryReport', signatureId, getUserInfo(user).token],
    queryFn: async () => {
      const result = await axios.get(
        `get-signature?id=${projectId}&route_id=${routeId}&signature_id=${signatureId}`,
        {
          headers: {
            Authorization: `Bearer ${getUserInfo(user).token}`,
          },
        }
      );
      return _get(result, 'data.data', null);
    },
    onError: async (error) => {
      setToastValue({
        show: true,
        type: 'danger',
        message: _get(error, 'message', 'Something went wrong'),
      });
    },
    enabled: !!signatureId && !!user && !!projectId && !!routeId,
    retry: 1,
  });

  const getInfoByStatus = () => {
    if (status === STOP_STATUS.missed)
      return { text: 'skipped', icon: Images.iconCloseCircleOutline };
    return { text: 'completed', icon: Images.iconCheckMarkCircle };
  };

  const getHeaderTitle = () => {
    if (isStart) return `Start Details - ${vehicleName}`;
    if (isLastStop) return `End Details - ${vehicleName}`;
    return stopName;
  };

  const onCloseDetail = () => {
    setStopDetailSelected(null);
    setSelectedMarker(null);
  };

  const renderHeader = () => (
    <>
      {fromSearchBox ? (
        <div
          className={clsx(styles['header'], 'd-flex align-items-center px-3')}
        >
          <img
            src={Images.iconBackCircle}
            alt='Close'
            onClick={onCloseDetail}
          />
          <p className={clsx(styles['header__title'], 'ps-2')}>
            {stopName}
          </p>
        </div>
      ) : (
        <div
          className={clsx(
            styles['header'],
            'd-flex justify-content-between align-items-center px-3'
          )}
        >
          <div className='d-flex'>
            {(isStart || isLastStop) && (
              <img
                src={isStart ? Images.iconFlag : Images.iconHome}
                alt={isStart ? 'Start' : 'End'}
                className='me-2'
                style={{ height: 20 }}
              />
            )}
            <p className={styles['header__title']} title={getHeaderTitle()}>
              {getHeaderTitle()}
            </p>
          </div>
          <img
            src={Images.iconCloseSquare}
            alt='Close'
            onClick={onCloseDetail}
          />
        </div>
      )}
    </>
  );

  useEffect(() => {
    if (contentRef && contentRef.current) contentRef.current.scrollTop = 0;
  }, [data]);

  return (
    <div ref={ref} className={styles['container']}>
      {renderHeader()}
      {isStart || isLastStop ? (
        <div className={styles['content']}>
          <div className={styles['line-horizontal']}></div>
          <div className={clsx(styles['stop-info'], 'px-3 py-3')}>
            <StopSection label='Driver Name' value={vehicleName} />
            {isStart && address && (
              <StopSection label='Start Address' value={address} />
            )}
            {isLastStop && address && (
              <StopSection label='End Address' value={address} />
            )}
            {availability && (
              <StopSection label='Availability' value={availability} />
            )}
            {isStart && visitArrivalTime && (
              <StopSection label='Actual Start Time' value={visitArrivalTime} />
            )}
            {isLastStop && visitArrivalTime && (
              <StopSection label='Actual End Time' value={visitArrivalTime} />
            )}
          </div>
        </div>
      ) : (
        <div className={styles['content']} ref={contentRef}>
          {status !== STOP_STATUS.scheduled && (
            <div
              className={clsx(
                styles['header'],
                'd-flex align-items-center px-3'
              )}
              style={{ backgroundColor: '#6B8ADB' }}
            >
              <img
                src={getInfoByStatus().icon}
                alt='Close'
              />
              <p className='ps-2'>
                <span>{vehicleName}</span> {getInfoByStatus().text} this visit at{' '}
                {visitArrivalTime}
              </p>
            </div>
          )}
          <div
            className='d-flex px-3 py-3 flex-column'
            style={{ background: '#F9F9F9' }}
          >
            <p
              className={clsx(styles['status'], 'text-center px-2')}
              style={{
                background: background,
                color: color,
                borderColor: color,
              }}
            >
              {status}
            </p>
            {/* DRIVER INFORMATION */}
            <div className={clsx(styles['driver-info'], 'mt-3')}>
              <p className={styles['driver-info__header']}>
                DRIVER INFORMATION
              </p>
              <div className='mt-3'>
                <p className={styles['driver-info__label']}>Driver Name</p>
                <p className={clsx(styles['driver-info__text'], 'mt-2')}>
                  {vehicleName}
                </p>
              </div>
              {status === STOP_STATUS.scheduled ? null : (
                <>
                  <div
                    className='mt-3'
                    style={{ display: driverNote ? 'block' : 'none' }}
                  >
                    <p className={styles['driver-info__label']}>Note</p>
                    <p className={clsx(styles['driver-info__text'], 'mt-2')}>
                      {driverNote}
                    </p>
                  </div>
                  {signatureData && (
                    <div className='mt-3'>
                      <p className={styles['driver-info__label']}>Signature</p>
                      <img
                        src={`data:image/png;base64, ${signatureData}`}
                        alt='Signature'
                        style={{ height: 75 }}
                        className='mt-3'
                      />
                    </div>
                  )}
                  {attachment && (
                    <div className='mt-3'>
                      <p className={styles['driver-info__label']}>Attachment</p>
                      <a href={attachment} target='_blank' rel='noreferrer'>
                        <img
                          style={{ height: 100 }}
                          src={attachment}
                          alt='Attachment'
                          className='mt-2'
                        />
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {/* STOP INFORMATION */}
          <div className={clsx(styles['stop-info'], 'px-3 py-3')}>
            <p className={styles['stop-info__header']}>STOP INFORMATION</p>
            <StopSection label='Name' value={stopName} />
            <StopSection label='Address' value={address} />
            <StopSection label='Original Start Time' value={originalStartTime || startTime || arrivalTime} />
            <StopSection label='Notes' value={stopNote} />
            <StopSection label='Mobile number' value={phoneNumber} />
            <StopSection label='Email' value={email} />
            <StopSection label='Types' value={types} />
            <StopSection label='Practice code' value={practiceCode} />
          </div>
        </div>
      )}
    </div>
  );
};

export default StopDetail;
