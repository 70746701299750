/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { PropTypes } from 'prop-types';
import Images from '../../assets/images';
import clsx from 'clsx';
import _get from 'lodash/get';
import { STOP_ITEM_REPORT_INDICATOR } from '../../utils/constants/Label';
import { STOP_ITEM_REPORT_DOT_INDICATOR } from '../../utils/constants/common';

// Styles
import styles from './CustomToggle.module.scss';

const INDICATOR_DISPLAY = [
  STOP_ITEM_REPORT_INDICATOR.scheduled,
  STOP_ITEM_REPORT_INDICATOR.onTime,
  STOP_ITEM_REPORT_INDICATOR.early,
  STOP_ITEM_REPORT_INDICATOR.late,
  STOP_ITEM_REPORT_INDICATOR.missed,
];

const Indicator = ({ data, field }) => {
  const value = _get(data, field, 0);
  return (
    <div
      className={`d-flex align-items-center w-25 opacity-${
        value <= 0 ? 0 : 100
      }`}
    >
      <div
        className={clsx(styles['dot-indicator'], 'me-2')}
        style={{ background: STOP_ITEM_REPORT_DOT_INDICATOR[field] }}
      ></div>
      <p className={styles['stop-num']}>{value}</p>
    </div>
  );
};

const CustomToggle = ({ eventKey, data, setOpenKeyValue, openKey }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSetOpenKey = () => {
    if (openKey === eventKey) decoratedOnClick();
    else setOpenKeyValue(eventKey);
  };

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setIsOpen(!isOpen);
  });

  useEffect(() => {
    if (!openKey) return;
    if (openKey !== eventKey && isOpen) decoratedOnClick();
    if (openKey === eventKey) decoratedOnClick();
  }, [openKey]);

  

  return (
    <div
      className={clsx(
        styles['container'],
        'd-flex row w-100 align-items-center'
      )}
      onClick={handleSetOpenKey}
    >
      <div className='col-4 d-flex align-items-center'>
        <img
          src={Images.iconArrowDown}
          alt={`Arrow ${isOpen ? 'up' : 'down'}`}
          className={isOpen ? styles['arrow-up'] : styles['arrow-down']}
        />
        <p
          className={clsx(styles['stop-title'], 'ms-2')}
          title={_get(data, 'stop_name', '')}
        >
          {_get(data, 'stop_name', '')}
        </p>
      </div>
      <div className={clsx(styles['stop-total'], 'col-3')}>
        <p>{_get(data, 'totalStopsRoute', 0)} Stops</p>
      </div>
      <div className='col-5 d-flex justify-content-end p-0'>
        {INDICATOR_DISPLAY.map((item, index) => (
          <Indicator key={index} data={data} field={item} />
        ))}
      </div>
    </div>
  );
};

CustomToggle.propTypes = {
  eventKey: PropTypes.any,
  openKey: PropTypes.any,
  data: PropTypes.object,
  setOpenKeyValue: PropTypes.func,
};

export default CustomToggle;
