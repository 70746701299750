import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from '../Sidebar'
import Content from "../Content";
import { AppStateContext } from "../../context/AppStateContext";

const MainLayout = ({ children }) => {
  const { isOpenMenu, setIsOpenMenu, hideHeader } = useContext(AppStateContext);
    const [selectedRoute, setSelectedRoute] = React.useState(window.location.pathname ?? '/');

    const toggle = () => {
      setIsOpenMenu(!isOpenMenu);
    }

    return (
      <div className="App wrapper">
        <SideBar
          selectedRoute={selectedRoute}
          setSelectedRoute={setSelectedRoute}
          toggle={toggle}
          isOpen={isOpenMenu}
          setIsOpen={setIsOpenMenu}
        />
        <Content selectedRoute={selectedRoute} toggle={toggle} isOpen={isOpenMenu} children={children} hideHeader={hideHeader} />
      </div>
    );
}

export default MainLayout;