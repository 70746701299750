import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const TooltipCustom = (props) => {
  const { placement, children, icon, className } = props;
  return (
    <>
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={
          <Tooltip
            id={`tooltip-${placement}`}
            className={className}
            style={{ position: 'fixed' }}
          >
            {children}
          </Tooltip>
        }
      >
        {icon && (
          <img
            src={icon}
            alt='Info'
            role='button'
            className='ms-2'
            style={{ width: 14 }}
          />
        )}
      </OverlayTrigger>
    </>
  );
};

TooltipCustom.propTypes = {
  placement: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.any,
  children: PropTypes.element,
  icon: PropTypes.any,
};

export default TooltipCustom;
