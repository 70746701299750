import React from 'react';
import RoutificTable from './Table';

const RoutificProjects = (props) => {
  const { data = [], total = 0, hasNext = false, page = 1, setPage, setSort, sort, setIsSorting } = props || {};
  return (
    <RoutificTable
      data={data}
      total={total}
      hasNext={hasNext}
      page={page}
      setPage={setPage}
      sort={sort}
      setSort={setSort}
      setIsSorting={setIsSorting}
    />
  );
};

export default RoutificProjects;
