import _maxBy from 'lodash/maxBy';

const timeMilestonesBase = [
  {
    time: "08:00",
    position: ""
  },
  {
    time: "09:00",
    position: ""
  },
  {
    time: "10:00",
    position: ""
  },
  {
    time: "11:00",
    position: ""
  },
  {
    time: "12:00",
    position: ""
  },
  {
    time: "13:00",
    position: ""
  },
  {
    time: "14:00",
    position: ""
  },
  {
    time: "15:00",
    position: ""
  },
  {
    time: "16:00",
    position: ""
  },
  {
    time: "17:00",
    position: ""
  },
  {
    time: "18:00",
    position: ""
  },
  {
    time: "19:00",
    position: ""
  },
  {
    time: "20:00",
    position: ""
  }
];

export const timeMilestonesData = timeMilestonesBase.map((item, index) => {
  return {
    ...item,
    position: `${(100 * index) / 12}%`
  };
});

export function groupVanBy15Minutes(timelineData) {
  const groupedData = {};

  for (const item of timelineData) {
    if (!item.eta) continue;
    const eta = item.eta;
    const timeParts = eta.split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const totalMinutes = hours * 60 + minutes;
    let groupKey = Math.floor(totalMinutes / 15) * 15;
    let anchorTime = '00:00'

    if (totalMinutes === 1200) { // Group 20:00 into 19:45 group time
      groupKey = Math.floor((totalMinutes - 15) / 15) * 15;
    }

    // calculate anchorTime
    const anchorHour = (Math.floor(groupKey / 60)).toString()
    const anchorMinute = (Math.floor(groupKey % 60) + 7).toString()
    anchorTime = `${anchorHour.length > 1 ? anchorHour : 0 + anchorHour}:${anchorMinute.length > 1 ? anchorMinute : 0 + anchorMinute}`

    if (totalMinutes < 480) { // van markers' time less than 08:00
      if (groupedData['lessThan8am']) {
        groupedData['lessThan8am'].push({...item})
      } else {
        groupedData['lessThan8am'] = [{...item}]
      }
    }

    if (totalMinutes > 1200) { // van markers' time greater than 20:00
      if (groupedData['greaterThan8pm']) {
        groupedData['greaterThan8pm'].push({...item})
      } else {
        groupedData['greaterThan8pm'] = [{...item}]
      }
    }

    if (totalMinutes >= 480 && totalMinutes <= 1200) { // van markers' time from 08:00 to 20:00
      if (groupedData[groupKey]) {
        groupedData[groupKey].push({...item, anchorTime});
      } else {
        groupedData[groupKey] = [{...item, anchorTime}];
      }
    }
  }

  return groupedData;
}

export function convertTime(time) {
  let position = 0; // in percentage
  const totalTime = 720; // 08:00 to 20:00 in minutes
  const startTime = 480; // 00:00 to 08:00 in minutes
  const endTime = 1200; // 00:00 to 20:00 in minutes

  if (!time) return position;
  const timeParts = time.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const totalMinutes = hours * 60 + minutes;
  if (totalMinutes > endTime) return 100
  if (totalMinutes >= startTime) {
    const totalMinutesFromStartTime = totalMinutes - startTime;
    position = (totalMinutesFromStartTime / totalTime) * 100;
  }

  return position;
}

export function convertTimeToPosition(groupedTimelineData) {
  let convertedGroupedTimelineData = [];

  if (Object.keys(groupedTimelineData).length > 0) {

    Object.keys(groupedTimelineData).forEach((key) => {
      convertedGroupedTimelineData.push({
        groupKey: key,
        vanMarkers: groupedTimelineData[key]
      });
    });
  
    convertedGroupedTimelineData = convertedGroupedTimelineData.map(
      (item) => {
        return {
          ...item,
          vanMarkers: item.vanMarkers.map((vanMarker) => {
            return {
              ...vanMarker,
              position: convertTime(vanMarker.anchorTime || vanMarker.eta)
            };
          })
        };
      }
    );
  }

  return convertedGroupedTimelineData;
}

function justifyCurrentTime(groupTime, justifiedMinute) {
  const currentHour = (Math.floor(groupTime / 60)).toString()
  const currentMinute = (Math.floor(groupTime % 60) + (justifiedMinute)).toString()
  const justifiedCurrentTime = `${currentHour.length > 1 ? currentHour : 0 + currentHour}:${currentMinute.length > 1 ? currentMinute : 0 + currentMinute}`

  return justifiedCurrentTime
}

// Calculate Current Time
// @Determine groupTime where Current Time is located
// @Find the latest time in van markers in that groupTime
// @Justify Current Time based on the latest time in van markers

export function calculateCurrentTime(currentTime, vanMarkerList) {
  if (!currentTime) return convertTime(currentTime)
  const timeParts = currentTime.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const totalMinutes = hours * 60 + minutes;
  const groupTime = Math.floor(totalMinutes / 15) * 15;
  let currentGroupTime = {} // Group time contains van markers where Clock Icon is located
  let justifiedCurrentTime = currentTime

  currentGroupTime = vanMarkerList.find(vanMarkerGroup => vanMarkerGroup.groupKey === groupTime.toString())

  if (currentGroupTime) {
    const latestTimeInGroup = _maxBy(currentGroupTime.vanMarkers, 'eta')

    if (currentTime > latestTimeInGroup.eta && currentTime <= latestTimeInGroup.anchorTime) {
      // Justify currentTime to display +4 min to the right of the anchorTime
      justifiedCurrentTime = justifyCurrentTime(groupTime, 11)
    }

    if (currentTime >= latestTimeInGroup.anchorTime && currentTime < latestTimeInGroup.eta) {
      // Justify currentTime to display -4 min to the left of the anchorTime
      justifiedCurrentTime = justifyCurrentTime(groupTime, 3)
    }
  }

  return convertTime(justifiedCurrentTime)
}

// Calculate Van Markers Position

export const calculatePosition = (vanMarkerData, groupKey) => {
  let styles = null
  styles = {left: `${vanMarkerData.position}%`}
  if (groupKey === 'lessThan8am') styles = {left: '-30px'}
  if (groupKey === 'greaterThan8pm') styles = {right: '-40px'}
  return styles
}
