import React from 'react';
import { useNavigate } from 'react-router-dom';

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <button style={{ float: 'right' }} onClick={() => navigate(-1)}>
        Back
      </button>
    </>
  );
};

export default BackButton;
