import {
  DRIVER_INFO_LABEL,
  PROJECT_FIELD,
  PROJECT_STATUS,
} from '../constants/Label';
import { DATE_TIME_FORMAT, STOP_STATUS_INFO } from '../constants/common';
import { formatDateTime } from './formatDateTime';
import _get from 'lodash/get';

const getProjectStatusColor = (status = '') => {
  switch (status) {
    case PROJECT_STATUS.dispatched:
      return '#6BB7DB';
    case PROJECT_STATUS.inprogress:
      return '#FFC533';
    case PROJECT_STATUS.completed:
      return '#38B74D';
    default:
      return '';
  }
};

const getDisplayValueOfStop = (status = '') => {
  const { icon, color, width, height } =
    STOP_STATUS_INFO[status.replace('-', '').toLowerCase()] || {};
  return {
    label: status,
    icon: icon || '',
    color: color || '',
    width: width,
    height,
  };
};

const getItemValueProjectInfo = (projectInfo, field) => {
  const value = _get(projectInfo, field, '');

  switch (field) {
    case PROJECT_FIELD.name:
    case PROJECT_FIELD.idleTime:
      return {
        value,
      };
    case PROJECT_FIELD.date:
      return {
        value: formatDateTime(value, DATE_TIME_FORMAT.DD_MMM_YYYY),
      };
    case PROJECT_FIELD.status:
      const color = getProjectStatusColor(value);
      return {
        value,
        color,
      };
    default:
      return {
        value,
      };
  }
};

const getInfoOfDriver = (driverInfo, field) => {
  if (!driverInfo) return;
  const shiftStart = _get(driverInfo, 'shift-start', '');
  const shiftEnd = _get(driverInfo, 'shift-end', '');
  const availability = `${shiftStart} - ${shiftEnd || ''}`;
  const types = _get(driverInfo, 'types', [])?.join(', ');
  const lunchBreak = _get(driverInfo, 'breaks', [])
    .map((item) => `${item.start} - ${item.end}`)
    ?.join(', ');

  switch (field) {
    case DRIVER_INFO_LABEL.name:
      return _get(driverInfo, 'name', '');
    case DRIVER_INFO_LABEL.phoneNumber:
      return _get(driverInfo, 'phone-number', '');
    case DRIVER_INFO_LABEL.startAddress:
      return _get(driverInfo, 'start-location.address', '');
    case DRIVER_INFO_LABEL.endAddress:
      return _get(driverInfo, 'end-location.address', '');
    case DRIVER_INFO_LABEL.availability:
      return availability;
    case DRIVER_INFO_LABEL.capacity:
      return _get(driverInfo, 'capacity', '');
    case DRIVER_INFO_LABEL.type:
      return types;
    case DRIVER_INFO_LABEL.break:
      return lunchBreak;
    default:
      return '';
  }
};

export {
  getProjectStatusColor,
  getDisplayValueOfStop,
  getItemValueProjectInfo,
  getInfoOfDriver,
};
