import React, { useState, createContext } from 'react';
import ToastCustom from '../components/Toast';

export const AppStateContext = createContext();

const AppStateContextProvider = (props) => {
  const [toastValue, setToastValue] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  return (
    <AppStateContext.Provider value={{ toastValue, setToastValue, isOpenMenu, setIsOpenMenu, hideHeader, setHideHeader }}>
      <ToastCustom toastValue={toastValue} setToastValue={setToastValue} />
      {props.children}
    </AppStateContext.Provider>
  );
};

export default AppStateContextProvider;
