import clsx from 'clsx';
import React, { memo } from 'react';
import RouteCard from '../../components/RouteCard';
import styles from './styles.module.scss';

const RouteListCard = memo(
  ({
    showRouteList,
    routeList,
    onClickStop,
    onHideSingleRoute,
    routeListNameHidden,
    toolTipPosition
  }) => (
    <div
      className={clsx(
        styles['route-list__content'],
        `d-flex flex-row px-4 pt-3 pb-2 overflow-auto ${
          showRouteList ? '' : 'd-none'
        }`
      )}
    >
      {routeList?.map((route) => (
        <RouteCard
          data={route}
          key={route?._id}
          onClickStop={onClickStop}
          onHideSingleRoute={onHideSingleRoute}
          routeListNameHidden={routeListNameHidden}
          toolTipPosition={toolTipPosition}
        />
      ))}
    </div>
  )
);

export default RouteListCard;
