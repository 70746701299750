import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import AutoSuggest from '../components/Typeahead';
import PracticePage from './PracticePage';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '../App.css';

const SearchPage = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState('Search');
  const [json, setJson] = useState({});

  const handleClick = (json) => {
    const newState = active === 'Search' ? 'Results' : 'Search';
    setActive(newState);
    setJson(json);
    navigate(`/dashboard/${json?.Practice_Code}`);
  };

  return (
    <Container>
      {active === 'Search' && (
        <div className='typeahead'>
          <AutoSuggest callback={handleClick} />
        </div>
      )}
      {active === 'Results' && <PracticePage back={handleClick} json={json} />}
    </Container>
  );
};

export default withAuthenticator(SearchPage, {
  hideSignUp: true
});
