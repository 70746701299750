import React from 'react';
import clsx from 'clsx';
import Images from '../../assets/images';
import StopItem from '../StopItem/index';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Button } from 'react-bootstrap';
import TooltipCustom from '../Tooltip';
import { DRIVER_INFO_LABEL } from '../../utils/constants/Label';
import { getInfoOfDriver } from '../../utils/helpers/getProjectInfo';
import { LIVE_TRACKING } from '../../utils/constants/common';
import styles from './styles.module.scss';

const ItemInfoTooltip = ({ label, value }) => {
  return (
    <div className='d-flex flex-row justify-content-between mt-2'>
      <p className={clsx(styles['tooltip-label'], 'text-nowrap')}>{label}</p>
      <p className={clsx(styles['tooltip-value'], 'text-end me-2')}>{value}</p>
    </div>
  );
};

const ItemInfo = ({ label, value }) => (
  <div className='d-flex justify-content-between'>
    <p className={styles['card-info__label']}>{label}</p>
    <p className={styles['card-info__value']}>{value}</p>
  </div>
);

const RouteCard = (props) => {
  const {
    data,
    onClickStop,
    onHideSingleRoute,
    routeListNameHidden = [],
    toolTipPosition,
  } = props;
  const driverInfo = _get(data, 'vehicle', null);
  const visits = _get(data, 'solution.visits', []);
  const liveTracking = _get(data, 'live_tracking_status', '');
  const routeName = _get(data, 'routeName', '');
  const lastStopIndex = visits?.findIndex((item) => item?.isLastStop);
  // Get visit point from start to last stop, ignore item after last stop point
  const visitsShow =
    lastStopIndex === -1 ? visits : visits.slice(0, lastStopIndex + 1);

  const onHideRoute = () => onHideSingleRoute(routeName);

  return (
    <div className={clsx(styles['card'], 'd-flex flex-column')} id='route-card'>
      <div className={styles['top-content']}>
        <div
          className={clsx(
            styles['card-header'],
            'd-flex justify-content-between align-items-center'
          )}
        >
          <div className='d-flex align-items-center'>
            <Button
              variant='light'
              className='d-flex align-items-center border-0 p-0'
              onClick={onHideRoute}
              title='Hide/Show route'
            >
              <img
                src={
                  routeListNameHidden?.includes(routeName)
                    ? Images.iconEyeClose
                    : Images.iconEyeOpen
                }
                alt='Eye'
              />
            </Button>

            <p
              className={clsx(styles['card-header__name'], 'ms-2')}
              title={_get(data, 'vehicle.name', '')}
            >
              {_get(data, 'vehicle.name', '')}
            </p>
            <div
              className={clsx(styles['card-header__mark-color'], 'ms-2')}
              style={{ backgroundColor: data?.color }}
            ></div>
            <TooltipCustom
              placement={toolTipPosition}
              icon={Images.iconInfo}
              className={styles['tooltip-container']}
            >
              <div className='d-flex flex-column w-100'>
                <p
                  className={clsx(styles['tooltip-title'], 'align-self-start')}
                >
                  Driver Information
                </p>
                <div>
                  {Object.values(DRIVER_INFO_LABEL).map((item, index) => {
                    const value = getInfoOfDriver(driverInfo, item);
                    if (!value) return null;
                    return (
                      <ItemInfoTooltip key={index} label={item} value={value} />
                    );
                  })}
                </div>
              </div>
            </TooltipCustom>
          </div>
          {liveTracking !== 'hidden' && (
            <div className='d-flex text-end text-nowrap align-items-center'>
              <div
                className={clsx(styles['live-tracking__dot'], 'mx-2')}
                style={{ background: LIVE_TRACKING[liveTracking]?.color }}
              ></div>
              <p className={styles['live-tracking__label']}>
                {LIVE_TRACKING[liveTracking]?.label}
              </p>
            </div>
          )}
        </div>
        <div className={styles['card-break-line']}></div>
        <div className={clsx(styles['card-info'])}>
          <ItemInfo
            label='Working minutes'
            value={_get(data, 'total_driver_working_time_mins', '')}
          />
          <ItemInfo
            label='Total stops'
            value={`${_get(data, 'total_completed_stops', '')}/${_get(
              data,
              'total_stops',
              ''
            )}`}
          />
          <ItemInfo
            label='Total miles'
            value={_get(data, 'total_miles', 0).toFixed(1)}
          />
        </div>
        <div className={styles['card-break-line']}></div>
      </div>

      <div className={styles['card-info__content']}>
        {visitsShow.map((item, index) => (
          <StopItem
            key={`${item?.order}-${item?.start_time}-${item?.break}`}
            data={item}
            liveTracking={liveTracking}
            isStartingPoint={index === 0}
            onClickStop={onClickStop}
            stopIndex={index}
            routeSize={visitsShow?.length}
            routeName={_get(data, 'routeName', '')}
            // disableButton={routeListNameHidden.includes(routeName)}
            disableButton={false}
          />
        ))}
      </div>
    </div>
  );
};

RouteCard.propTypes = {
  data: PropTypes.object,
  onClickStop: PropTypes.func,
};

export default RouteCard;
