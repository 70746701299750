import _get from 'lodash/get';
import { calculatePosition } from '../timeMilestones';
import Images from '../../../../../assets/images';
import styles from './styles.module.scss';

const VanGroupMarker = ({ vanGroupMarker }) => {
  const {groupKey, vanMarkers} = vanGroupMarker
  const vanMarkerData = vanMarkers[0]

  return (
    <div style={calculatePosition(vanMarkerData, groupKey)} className={`${styles['van-group-marker']} position-absolute`}>
      <div className={`${styles['van-group-marker__dot']} position-absolute`}></div>
      <div className={`${styles['van-group-marker__tag']} position-absolute`}>
        {vanMarkers.length}
        <div className={`${styles['van-group-marker__popup']} row g-3`}>
          {
            vanMarkers.map(vanMarker => (
              <div key={vanMarker?.deliveredBy} className="col-12">
                <div style={{backgroundColor: `${_get(vanMarker, 'color', '#a59e9e')}`}} className={`${styles['van-group-marker__item']}`}>
                  <img
                    src={Images.thinBox}
                    alt='Thin Box'
                    className='me-2'
                  />
                  <span
                    className='text-truncate'
                    title={`${_get(vanMarker, 'deliveredBy', 'N/A')}`}
                  >
                    {_get(vanMarker, 'deliveredBy', 'N/A')}
                  </span>
                  <span className="ms-1">- {_get(vanMarker, 'eta', 'N/A')}</span>
                  {
                    !vanMarker?.isCompleted && (
                      <img
                        src={Images.whiteTruckFast}
                        alt='Estimated Delivery Time'
                        className="ms-2"
                        title='Estimated Delivery Time'
                      />
                    )
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default VanGroupMarker
