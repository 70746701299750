import React from 'react';
import styles from './styles.module.scss';

const CheckboxButton = ({ checked = false, handleClick, label }) => {
  return (
    <div className={`${styles['checkbox-button']} d-flex align-items-center justify-content-between`}>
      <p className={`${styles['checkbox-label']} text-truncate`} title={label}>{label}</p>
      <input
        type='checkbox'
        checked={checked}
        onChange={handleClick}
      />
    </div>
  );
};

export default CheckboxButton;
