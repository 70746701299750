import Images from '../../assets/images';

const DATE_TIME_FORMAT = {
  MM_DD_YYYY_hma: 'MM/DD/yyyy, h:mm a',
  MMM_DD_YYYY: 'MMM DD, YYYY',
  DD_MMM_YYYY: 'DD MMM, YYYY',
  HHmmss: 'HH:mm:ss',
  HHmm: 'HH:mm',
  YYYY_MM_DD: 'YYYY-MM-DD'
};

const STATUS_COLOR = {
  scheduled: '#8A8A8A',
  onTime: '#62B58F',
  early: '#29C3BE',
  late: '#FDB561',
  missed: '#FD6561',
};

const MARK_COLOR = [
  '#38B74D',
  '#FDB561',
  '#6855DA',
  '#2C74B3',
  '#5F8D4E',
  '#FFC533',
  '#5D62B5',
  '#47B5FF',
  '#F38E1A',
  '#569DAA',
  '#29C3BE',
  '#FD6199',
  '#146C94',
  '#F97B22',
];

const STOP_STATUS_INFO = {
  start: {
    icon: Images.iconMarkerStart,
    color: '#222222',
  },
  scheduled: {
    icon: Images.iconMarkerSchedule,
    color: '#8A8A8A',
  },
  ontime: {
    icon: Images.iconMarkerOnTime,
    color: '#62B58F',
    background: 'rgba(98, 181, 143, 0.2)',
  },
  early: {
    icon: Images.iconEarly,
    color: '#29C3BE',
    background: 'rgba(41, 195, 190, 0.2)',
  },
  late: {
    icon: Images.iconMarkerLate,
    color: '#FDB561',
    background: 'rgba(253, 181, 97, 0.2)',
  },
  missed: {
    icon: Images.iconMissed,
    color: '#FD6461',
    background: 'rgba(253, 100, 97, 0.2)',
  },
  end: {
    icon: Images.iconMarkerDestination,
    color: '#222222',
    width: '18px',
    height: '27px',
  },
  break: {
    icon: Images.iconBreakLunch,
    color: '#222222',
    width: '16px',
    height: '16px',
  },
};

const SUMMARY_REPORT_ITEM_INFO = {
  total_Scheduled_stops: {
    mainColor: STATUS_COLOR.scheduled,
    color: '#8A8A8A33',
    percentageField: 'percent_Scheduled_stops',
  },
  total_OnTime_stops: {
    mainColor: STATUS_COLOR.onTime,
    color: '#62B58F33',
    percentageField: 'percent_OnTime_stops',
  },
  total_Early_stops: {
    mainColor: STATUS_COLOR.early,
    color: '#29C3BE33',
    percentageField: 'percent_Early_stops',
  },
  total_Late_stops: {
    mainColor: STATUS_COLOR.late,
    color: '#FDB56133',
    percentageField: 'percent_Late_stops',
  },
  total_Missed_stops: {
    mainColor: STATUS_COLOR.missed,
    color: '#FD646133',
    percentageField: 'percent_Missed_stops',
  },
};

const STOP_ITEM_REPORT_DOT_INDICATOR = {
  total_Scheduled_stops_per_route: STATUS_COLOR.scheduled,
  total_OnTime_stops_per_route: STATUS_COLOR.onTime,
  total_Early_stops_per_route: STATUS_COLOR.early,
  total_Late_stops_per_route: STATUS_COLOR.late,
  total_Missed_stops_per_route: STATUS_COLOR.missed,
};

const LIVE_TRACKING = {
  live: {
    color: '#38B74D',
    label: 'Live',
  },
  completed: {
    color: '#D3D3D3',
    label: 'Done',
  },
  undispatched: {
    color: '#EE3B42',
    label: 'Failed',
  },
};

const LIVE_TRACKING_FIELD = {
  live: 'live',
  completed: 'completed',
  undispatched: 'undispatched'
}

const HEIGHT_EXPAND = {
  none: 0,
  half: 1,
  full: 2,
};

const SORT_ROUTE_BY = {
  name: 'Name',
  time: 'Time'
}

export {
  DATE_TIME_FORMAT,
  MARK_COLOR,
  STOP_STATUS_INFO,
  SUMMARY_REPORT_ITEM_INFO,
  STOP_ITEM_REPORT_DOT_INDICATOR,
  LIVE_TRACKING,
  HEIGHT_EXPAND,
  LIVE_TRACKING_FIELD,
  SORT_ROUTE_BY
};
