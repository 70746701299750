import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';
import Images from '../../assets/images';
import clsx from 'clsx';
// import Loading from '../../components/Loading';
import styles from './styles.module.scss';
import moment from 'moment';
import { PROJECT_STATUS } from '../../utils/constants/Label';

const FACTOR = 0.621371;

const getProjectStatusColor = (status = '') => {
  switch (status) {
    case PROJECT_STATUS.dispatched:
      return '#6BB7DB';
    case PROJECT_STATUS.inprogress:
      return '#FFC533';
    case PROJECT_STATUS.completed:
      return '#38B74D';
    default:
      return '';
  }
};

const columns = [
	{
		name: 'Project Name',
    width: '25%',
		selector: row => row.name,
    format: row => {
      return (
        <Link
          to={{
            pathname: `/routing/${row?._id}`,
          }}
          title={row.name}
        >
          {row.name}
        </Link>
      )
    },
    sortable: true,
    sortField: 'name',
	},
	{
		name: 'Project Date',
		selector: row => row.date,
    format: row => moment(row.date).format('DD/MM/YYYY'),
    sortable: true,
    sortField: 'date',
	},
  {
		name: 'Status',
		selector: row => row.status,
    format: row => {
      const color = getProjectStatusColor(row?.status);
      return <span style={{ color }}>{row.status}</span>
    },
    sortable: true,
    sortField: 'status',
	},
  {
		name: 'Total miles',
		selector: row => { 
      return (row?.distance * FACTOR)?.toFixed(2);
    },
    sortable: false
	},
  {
		name: 'Working mins',
		selector: row => row.workingTime,
    sortable: false
	},
  {
		name: 'Client',
		selector: row => row?.clientName,
    format: row => {
      return (
        <span
          style={{
            textDecoration: 'none',
            color: '#222'
          }}
          title={row.clientName}
        >
          {row.clientName}
        </span>
      )
    },
    sortable: true,
    sortField: 'clientName'
	},
];

const RoutificTable = ({ data, total, hasNext, page, setPage, setSort, sort, setIsSorting }) => {
  const makeUpData = useMemo(() => data?.map((row) => {
    return {
      ...row,
      id: row._id
    }
  }), [data]);

  const customStyles = {
    head: {
      style: {
        // border: '1px solid #E9E9E9',
        // borderTopLeftRadius: '10px',
      }
    },
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#222',
        borderLeft: '1px solid #E9E9E9',
        borderRight: '1px solid #E9E9E9',
        '&:last-child': {
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '10px',
          borderBottom: '1px solid #E9E9E9',
        }
      }
    },
    headCells: {
      style: {
        background: '#F9F9F9',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        fontSize: '14px',
        fontWeight: '500',
        padding: '22px',
        color: '#8A8A8A',
        borderTop: '1px solid #E9E9E9',
        '&:first-child': {
          borderTopLeftRadius: '10px',
          borderLeft: '1px solid #E9E9E9',
        },
        '&:last-child': {
          borderTopRightRadius: '10px',
          borderRight: '1px solid #E9E9E9',
        }
      },
    },
    cells: {
      style: {
        padding: '22px'
      },
    },
  };

  const handleSort = async (column, sortDirection) => {
    setTimeout(() => {
      setSort({
        [column.sortField]: sortDirection
      });
    }, 100);
	};

  return (
    <div className='mt-3'>
      {
        data.length === 0 ? (
          <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: '83vh' }}>
            <img src={Images.NoData} alt="" />
            <div style={{
              color: '#222',
              fontSize: '18px',
              fontWeight: '400',
              fontStyle: 'normal',
              marginTop: '15px'
            }}>No data available</div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={makeUpData}
            onSort={handleSort}
            sortServer
            pagination
            // fixedHeader
            // fixedHeaderScrollHeight="60vh"
            customStyles={customStyles}
            paginationComponentOptions={
              {
                noRowsPerPage: true,
              }
            }
            paginationComponent={() => {
              return (
                <div className='d-flex align-items-center justify-content-between mt-4'>
                  <span className='fw-normal' style={{
                    fontSize: '12px',
                    color: '#A1A8B2'
                  }}>Showing {data.length} of {total} items</span>
                  <div className='d-flex flex-row gap-2'>
                    <Button
                      className={clsx(styles['custom-pagination-button'])}
                      variant="light"
                      disabled={page === 1}
                      onClick={() => {
                        if (page > 1) {
                          setIsSorting(false);
                          setPage(page - 1)
                        }
                      }}
                    >
                      <img width="16" height="16" src={Images.PreviousPage} alt="" />
                      <span>Prev</span>
                    </Button>
                    <Button
                      className={clsx(styles['custom-pagination-button'])}
                      variant="light"
                      disabled={!hasNext}
                      onClick={() => {
                        if (hasNext) {
                          setIsSorting(false);
                          setPage(page + 1)
                        }
                      }}
                    >
                      <span>Next</span>
                      <img width="16" height="16" src={Images.NextPage} alt="" />
                    </Button>
                  </div>
                </div>
              )
            }}
          />
        )
      }
    </div>
  );
};

export default RoutificTable;
