import { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import GoogleMapReact from 'google-map-react';
import { v1 as uuidv1 } from 'uuid';
import { _get } from 'lodash/get';

const GoogleRouteMap = (props) => {
  const places = _get(props, 'places', []);
  const [centerlat, setCenterLat] = useState(props.centerlat);
  const [centerlng, setCenterLng] = useState(props.centerlng);
  const [routes, setRoutes] = useState([]);
  const [routeLocations, setRouteLocations] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState('');
  const [mapKey, setMapKey] = useState(uuidv1());
  const [mapKey1, setMapKey1] = useState(uuidv1());

  const renderMarkers = (map, maps) => {
    var markers = [];
    for (var i = 0; i < routeLocations.length; i++) {
      var place = routeLocations[i];

      var marker = new maps.Marker({
        position: { lat: place.latitude, lng: place.longitude },
        map,
        title: place.address,
        icon:
          place.status === 'done'
            ? 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
      });

      markers.push(marker);

      if (i === 0) {
        setCenterLat(place.latitude);
        setCenterLng(place.longitude);
      }
    }

    return markers;
  };

  const clickIcon = (obj) => {
    console.log(obj);
  };

  useEffect(() => {
    setRoutes([...new Set(places.map((obj) => obj.vehicle_name))]);
  }, [places]);

  useEffect(() => {
    if (routes.length > 0) setSelectedRoute(routes[0]);
  }, [routes]);

  useEffect(() => {
    setRouteLocations(
      places.filter((place) => place.vehicle_name === selectedRoute)
    );
    setMapKey(uuidv1());
    setMapKey1(uuidv1());
  }, [selectedRoute, places]);

  return (
    <div className='row'>
      <div
        key={mapKey1}
        className='col-md-8'
        style={{ height: '750px', position: 'relative' }}
      >
        <div
          style={{
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            width: '700px',
          }}
        >
          <GoogleMapReact
            key={mapKey}
            bootstrapURLKeys={{
              key: '<YOUR_GOOGLE_MAPS_API_KEY>',
            }}
            defaultCenter={{ lat: centerlat, lng: centerlng }}
            defaultZoom={15}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            onClick={clickIcon}
          ></GoogleMapReact>
        </div>
      </div>
      <div
        className='col-md-4'
        style={{ textAlign: 'left', paddingLeft: '10px' }}
      >
        <Image src='http://maps.google.com/mapfiles/ms/icons/blue-dot.png' /> -
        Stop complete
        <br />
        <Image src='http://maps.google.com/mapfiles/ms/icons/red-dot.png' /> -
        Stop not completed
        <br />
        <div style={{ maxHeight: '700px', overflowX: 'scroll' }}>
          <Table responsive='sm'>
            <thead>
              <tr>
                <th>Routes</th>
              </tr>
            </thead>
            <tbody>
              {routes?.map((json, idx) => {
                return (
                  <tr key={idx}>
                    <td
                      onClick={() => this.selectRoute(json)}
                      style={{
                        backgroundColor:
                          selectedRoute === json ? '#adade8' : 'white',
                      }}
                    >
                      {json}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default GoogleRouteMap;
