import moment from 'moment';
import { DATE_TIME_FORMAT } from '../constants/common';

const formatDateTime = (dateTime, type) => {
  const check = moment(dateTime).isValid();
  if (!check) return '';
  return `${moment(new Date(dateTime)).format(type)}`;
};

const convertDateObjHHMM = (hhmm = '') => {
  let obj = new Date(); //creates a Date Object using the clients current time
  const [hours, minutes] = hhmm.split(':');
  obj.setHours(+hours); // set the hours, using implicit type coercion
  obj.setMinutes(minutes); // pass Number or String, it doesn't really matter
  obj.setSeconds('0');
  return obj;
};

const getAverageTime = (times) => {
    let date = 0;
    let result = '';
  function offsetify(t) {
    return t < 10 ? '0' + t : t;
  }
  for (const element of times) {
    const tarr = element.split(':');
    date += new Date(0, 0, 0, tarr[0], tarr[1]).getTime();
  }
  const avg = new Date(date / times.length);
  result = offsetify(avg.getHours()) + ':' + offsetify(avg.getMinutes());
  return result
}

const checkIsCurrentDate = (dateTime) => {
  let isCurrentDate  = false
  const isValidDateTime = moment(dateTime).isValid();
  if (isValidDateTime) {
    const currentDate = moment().format(DATE_TIME_FORMAT.YYYY_MM_DD);
    isCurrentDate = dateTime === currentDate
  }
  return isCurrentDate
}

const addMinutesToTime = (time, minutesToAdd) => {
  const [hours, minutes] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes;
  // Add the desired minutes
  const newTotalMinutes = (totalMinutes + minutesToAdd) % (24 * 60);

  // Calculate the new hours and minutes
  const newHours = Math.floor(newTotalMinutes / 60);
  const newMinutes = newTotalMinutes % 60;

  // Format the new time value as a string with leading zeros
  const newTime = `${newHours.toString().padStart(2, "0")}:${newMinutes
    .toString()
    .padStart(2, "0")}`;

  return newTime;
};

export { formatDateTime, convertDateObjHHMM, getAverageTime, checkIsCurrentDate, addMinutesToTime };
