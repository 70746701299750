import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import clsx from 'clsx';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import { Modal } from 'react-bootstrap';

import DeliveryRow from './DeliveryRow/DeliveryRow';
import Timeline from './Timeline';
import NoResult from '../../NoResult';
import Images from '../../../assets/images';
import Loading from '../../Loading';
import styles from './styles.module.scss';

const SpecimensModal = (props) => {
  const { onHide, data, routeList, currentTime, projectDate } = props;
  const [currentHospital, setCurrentHospital] = useState('');
  const [hospitalList, setHospitalList] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const tableHeight = document.getElementById('arrival-table')?.offsetHeight;
  const [expandedRow, setExpandedRow] = useState(null)
  const [timelineData, setTimelineData] = useState([])

  const handleCurrentHospital = (value) => {
    return _get(data, 'specimenDashboard', []).filter(
      (i) => i.stopName === value
    );
  };

  const handleSelectedRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index)
  }

  const handleGoBack = () => {
    setExpandedRow(null)
    onHide()
  }

  useEffect(() => {
    if (!data) return;
    const deliveryHospital = _get(data, 'deliveryHospital', []);
    setCurrentHospital(currentHospital || _get(deliveryHospital, '[0]', ''));
    const options = deliveryHospital.map((item) => ({
      value: item,
      label: item,
    }));
    setDeliveryOptions(options);
    setHospitalList(handleCurrentHospital(currentHospital || _get(deliveryHospital, '[0]', '')))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (routeList?.length > 0 && hospitalList?.length > 0) {
      const timelineDataTemp = []
      _sortBy(hospitalList, ['eta']).forEach(deliveryVan => {
        routeList.forEach(route => {
          if (deliveryVan.deliveriedBy && deliveryVan.deliveriedBy === route.routeName) {
            timelineDataTemp.push({
              deliveredBy: deliveryVan.deliveriedBy,
              eta: deliveryVan.eta,
              isCompleted: deliveryVan.isCompleted,
              color: route.color
            })
          }
        })
      })
      setTimelineData(timelineDataTemp)
    }
  }, [hospitalList, routeList])

  if (!data) return <Loading />;

  return (
    <Modal
      {...props}
      dialogClassName=''
      contentClassName={clsx(styles['modal-content'], 'rounded-0')}
      className={styles['modal']}
      fullscreen={true}
      scrollable={false}
    >
      <Modal.Header className={clsx(styles['header'], 'rounded-0')}>
        <div className='d-flex align-items-center'>
          <img
            src={Images.iconBack}
            onClick={handleGoBack}
            alt='back'
            className='me-2'
            role='button'
          />
          <p
            className={clsx(styles['header__title'], 'ms-2 fw-bold')}
            title='Specimen Dashboard'
          >
            Arrivals Dashboard
          </p>
        </div>
      </Modal.Header>
      <Modal.Body className={clsx(styles['modal-body'], 'p-0')}>
        <div className='d-flex bg-light w-100 flex-column align-items-center overflow-hidden'>
          {/* Hospital Selection */}
          <div
            className={
              'd-flex text-white fw-bold w-75 py-4 flex-row justify-content-between align-items-center'
            }
          >
            <div className='d-flex'>
              <img src={Images.iconCrossAid} alt='Cross aid' className='me-2' />
              <p className={styles['hospital-section-text']}>
                {currentHospital}
              </p>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <p
                className={clsx(
                  styles['section-text'],
                  'text-dark me-3 fw-bold'
                )}
              >
                Delivery to:{' '}
              </p>

              <Select
                value={{
                  label: currentHospital,
                  value: currentHospital,
                }}
                onChange={(e) => {
                  setExpandedRow(null)
                  setCurrentHospital(e.value);
                  setHospitalList(handleCurrentHospital(e.value));
                }}
                options={deliveryOptions}
                className={styles['dropdown-container']}
                placeholder='Select a hospital'
                styles={{
                  control: (baseStyles, _state) => ({
                    ...baseStyles,
                    // borderColor: state.isFocused ? '#EAECF0' : '#EAECF0',
                    border: `1px solid #EAECF0`,
                    fontWeight: 400,
                    fontSize: 14,
                  }),
                  menu: (styles, _state) => ({
                    ...styles,
                    zIndex: 20,
                  }),
                  input: (styles, _state) => ({
                    ...styles,
                    color: '#F9F9F9',
                  }),
                  option: (
                    styles,
                    { isDisabled, isSelected } // other: data, isFocused
                  ) => {
                    return {
                      ...styles,
                      backgroundColor: isSelected ? '#F9F9F9' : '#FFF',
                      color: '#222222',
                      fontWeight: isSelected ? 'bold' : 400,
                      fontSize: 14,
                      cursor: isDisabled ? 'not-allowed' : 'pointer',
                    };
                  },
                }}
              />
            </div>
          </div>

          {/* Arrivals Timeline */}
          <Timeline timelineData={timelineData} currentTime={currentTime} projectDate={projectDate} />

          {/* Arrivals table */}

          <div className={clsx(styles['arrival-table-wrapper'], 'bg-white w-75')}>
            <h2>Arrivals</h2>
            <div
              id='arrival-table'
              className={clsx(styles['arrival-table'], 'bg-white w-100')}
            >
              <table className='table-hover'>
                <thead>
                  <tr>
                    <th className="ps-4">Delivered By</th>
                    <th>Delivery Time</th>
                    <th>No. Collections</th>
                    <th>Oldest</th>
                    <th>Youngest</th>
                    <th>Mean</th>
                    <th>Oldest From</th>
                  </tr>
                </thead>
                <tbody style={{ height: `calc(${tableHeight}px - 64px)` }}>
                  {hospitalList.length > 0 ?
                    hospitalList.map((hospital, index) => (
                      <DeliveryRow key={index} index={index} hospital={hospital} onSelectedRow={handleSelectedRow} expandedRow={expandedRow} />
                    )) :
                    <div className='h-100 d-flex align-items-center justify-content-center'><NoResult large /></div>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SpecimensModal;
