import React from 'react';
import { API } from 'aws-amplify';
import { withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import Phlebotomy from '../components/Phlebotomy';
import Loading from '../components/Loading';
import SpecimenCount from '../components/SpecimenCount';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoogleMaps from '../components/GoogleMaps';
import BackButton from '../components/BackButton';
import RouteInformation from '../components/RouteInformation';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router-dom'; // import the script
import { useQuery } from '@tanstack/react-query';
import { getUserInfo } from '../utils/helpers/getUserInfo';
import randomColor from 'randomcolor';

const title = {
  fontSize: '14',
  fontStyle: 'bold',
  display: 'block',
};

const field = {
  fontSize: '10',
};

const infobox = {
  textAlign: 'left',
};

const PracticePage = (props) => {
  const { json } = props;
  const history = createBrowserHistory();
  const params = useParams();
  const practiceCode = json?.Practice_Code ?? params?.id;
  const { user } = useAuthenticator((context) => [context.user]);
  const { token, groups } = getUserInfo(user);
  const group = groups.length ? groups[0] : null;

  const queryOptions = {
    enabled: !!user,
  };

  const { data: practiceData, isLoading: isLoadingPracticeData } = useQuery({
    queryKey: ['practiceData', user],
    queryFn: async () => {
      const apiName = 'MedDataApi_DynamoDB';
      const path = '/practices/' + practiceCode;
      const params = {
        headers: {
          Authorization: token,
        },
      };
      const data = await API.get(apiName, path, params);
      return data.length > 0 ? data[0] : null;
    },
    ...queryOptions,
  });

  const { data: phlebotomyData, isLoading: isLoadingPhlebotomyData } = useQuery(
    {
      queryKey: ['phlebotomyData', user],
      queryFn: async () => {
        const apiName = 'MedDataApi_DynamoDB';
        const path = '/phlebotomydata';
        const params = {
          headers: {
            Authorization: token,
          },
          queryStringParameters: {
            id: practiceCode,
          },
        };
        const data = await API.get(apiName, path, params);
        return data;
      },
      ...queryOptions,
    }
  );

  const { data: specimenCount, isLoading: isLoadingSpecimenCount } = useQuery({
    queryKey: ['specimenCount', user],
    queryFn: async () => {
      const apiName = 'MedDataApi_DynamoDB';
      const path = '/specimencount';
      const params = {
        headers: {
          Authorization: token,
        },
        queryStringParameters: {
          id: practiceCode,
          group: group,
        },
      };
      const data = await API.get(apiName, path, params);
      const count = [
        [
          'Date',
          'Specimen Count',
          { role: 'style' },
          {
            sourceColumn: 0,
            role: 'annotation',
            type: 'string',
            calc: 'stringify',
          },
        ],
      ];
      for (let i in data) {
        const color = randomColor();
        count.push([
          data[i].Request_Date,
          data[i].SpecimenCount,
          color,
          data[i].SpecimenCount,
        ]);
      }
      return count;
    },
    ...queryOptions,
  });

  const { data: routeData, isLoading: isLoadingRouteData } = useQuery({
    queryKey: ['routeData', user],
    queryFn: async () => {
      const apiName = 'MedDataApi_DynamoDB';
      const path = '/routedata';
      const params = {
        headers: {
          Authorization: token,
        },
        queryStringParameters: {
          id: practiceCode,
          group: group,
        },
      };
      const data = await API.get(apiName, path, params);
      return data;
    },
    ...queryOptions,
  });

  // const getTransportData = async (token) => {
  //   const apiName = "MedDataApi_DynamoDB";
  //   const path = "/transportdata";
  //   const params = {
  //     headers: {
  //       Authorization: token,
  //     },
  //     queryStringParameters: {
  //       id: practiceCode,
  //     },
  //   };
  //   return await API.get(apiName, path, params);
  // };

  const selectRoute = (json) => history.push('/route/' + json.routeId);

  return (
    <div className='container'>
      {isLoadingPracticeData ? (
        <Loading />
      ) : (
        <div>
          <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Col>
              <h1 style={infobox}>{practiceData.Practice_Name}</h1>
            </Col>
            <Col>
              <BackButton />
            </Col>
          </Row>
          <Row style={infobox}>
            <Col>
              <div style={title}>
                <h4>Practice Code</h4>
              </div>
              <div style={field}>
                <p>{practiceData.Practice_Code}</p>
              </div>
              <div style={title}>
                <h4>Address</h4>
              </div>
              <div style={field}>{practiceData.Address}</div>
              <div style={title}>
                <h4>Phone Number</h4>
              </div>
              <div style={field}>
                <p>{practiceData.Telephone}</p>
              </div>
              <div style={title}>
                <h4>Patient Size</h4>
              </div>
              <div style={field}>
                <p>{practiceData.Patient_Size}</p>
              </div>
            </Col>
            <Col>
              <div
                className='text-center'
                style={{ padding: 20, paddingLeft: 30 }}
              >
                <GoogleMaps
                  longitude={practiceData.Longitude}
                  latitude={practiceData.Latitude}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
      {isLoadingRouteData ? (
        <Loading />
      ) : (
        <RouteInformation
          selectRoute={selectRoute}
          practiceId={practiceCode}
          data={routeData}
        />
      )}
      {/*{fetchingTransportData ? <Loading/> : <TransportData data={transportData}/>}*/}
      {isLoadingPhlebotomyData ? (
        <Loading />
      ) : (
        <Phlebotomy data={phlebotomyData} />
      )}
      {isLoadingSpecimenCount ? (
        <Loading />
      ) : (
        <SpecimenCount data={specimenCount} />
      )}
    </div>
  );
};

export default withAuthenticator(PracticePage, {
  hideSignUp: true
});
