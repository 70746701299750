import React from 'react';
import { ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import styles from './styles.module.scss';

const ToastCustom = (props) => {
  const {
    toastValue,
    toastValue: { show = false, message = '', type = 'light' },
    setToastValue,
  } = props;
  return (
    <ToastContainer
      position='top-end'
      className={styles['toast-container']}
    >
      <Toast
        show={show}
        onClose={() => setToastValue({ ...toastValue, show: !show })}
        delay={5000}
        autohide
        bg={type}
      >
        <Toast.Header>
          <strong className='me-auto'>Notification</strong>
        </Toast.Header>
        <Toast.Body className={type}>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastCustom;
