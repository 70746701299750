const PROJECT_LABEL = {
  name: 'Project Name',
  date: 'Project Date',
  totalStop: 'Number of Stops',
  status: 'Status',
};

const DRIVER_INFO_LABEL = {
  name: 'Driver Name',
  phoneNumber: 'Driver Phone',
  startAddress: 'Start Address',
  endAddress: 'End Address',
  availability: 'Availability',
  capacity: 'Capacity',
  type: 'Type',
  break: 'Lunch Break',
}

const PROJECT_FIELD = {
  name: 'name',
  date: 'date',
  idleTime: 'idleTime',
  visitCount: 'visitCount',
  status: 'status',
  totalStop: 'totalStop',
};

const PROJECT_STATUS = {
  dispatched: 'Dispatched',
  inprogress: 'In-progress',
  completed: 'Completed',
};

const STOP_STATUS = {
  start: 'Start',
  scheduled: 'Scheduled',
  onTime: 'On-time',
  late: 'Late',
  missed: 'Missed',
  unscheduled: 'Unscheduled',
  early: 'Early',
};

const SUMMARY_REPORT_FIELD = {
  scheduled: 'total_Scheduled_stops',
  onTime: 'total_OnTime_stops',
  early: 'total_Early_stops',
  late: 'total_Late_stops',
  missed: 'total_Missed_stops',
  totalStops: 'total_stops',
  workingMin: 'total_working_mins',
  totalMiles: 'total_miles',
};

const SUMMARY_REPORT_LABEL = {
  total_Scheduled_stops: 'Scheduled',
  total_OnTime_stops: 'On-time',
  total_Early_stops: 'Early',
  total_Late_stops: 'Late',
  total_Missed_stops: 'Missed',
  total_stops: 'Stops',
  total_working_mins: 'Working Minutes',
  total_miles: 'Total Miles',
};

const STOP_ITEM_REPORT_INDICATOR = {
  scheduled: 'total_Scheduled_stops_per_route',
  onTime: 'total_OnTime_stops_per_route',
  early: 'total_Early_stops_per_route',
  late: 'total_Late_stops_per_route',
  missed: 'total_Missed_stops_per_route',
};

export {
  PROJECT_LABEL,
  PROJECT_FIELD,
  PROJECT_STATUS,
  STOP_STATUS,
  SUMMARY_REPORT_FIELD,
  SUMMARY_REPORT_LABEL,
  STOP_ITEM_REPORT_INDICATOR,
  DRIVER_INFO_LABEL,
};
