import { Auth } from 'aws-amplify';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: `${baseUrl}/routific-api`,
  timeout: 60000,
});

const createAxiosResponseInterceptor = (axiosInstance) => {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log('ERROR_FIG:', error, error.response);
      await Auth.currentSession();
      // Reject promise if usual error
      if (error.status !== 401) {
        return Promise.reject(error);
      }
      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axiosInstance.interceptors.response.eject(interceptor);
    }
  );
};

createAxiosResponseInterceptor(instance);

export default instance;
