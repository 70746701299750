
const CollectionList = ({data = []}) => {
  return (
    <div className='d-flex flex-column align-items-start w-100'>
      <p className='title'>Collections List ({data?.length})</p>
      <div className={['collection-table']}>
        <div
          className={
            'collection-section-header d-flex align-items-center'
          }
        >
          <p className='text-start'>Stop Name</p>
          <p className='text-start'>Actual</p>
          <p className='text-start'>Scheduled</p>
        </div>
        <div className='collection-content'>
          {data.map((item, index) => (
            <div className={'item d-flex'}>
              <p className='text-start me-3' title={item?.stopName}>{index + 1}. {item?.stopName}</p>
              <p className='text-start'>{item?.actual}</p>
              <p className='text-start'>{item?.schedule}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CollectionList;
