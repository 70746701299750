import React, { useState, useEffect, forwardRef } from 'react';
import { withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import Loading from '../../components/Loading';
import RoutificProjects from '../../components/RoutificProjects';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery } from '@tanstack/react-query';
import { getUserInfo } from '../../utils/helpers/getUserInfo';
import styles from './styles.module.scss';
import clsx from 'clsx';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Images from '../../assets/images';
import { MultiSelect } from "react-multi-select-component";
import { useDebounce } from 'use-debounce';

// const options = [
//   { label: "DVH", value: "dvh" },
//   { label: "Medical Couriers UK", value: "medicalCouriersUK" },
//   { label: "NKPS", value: "nkps" },
//   { label: "Royal Mail Special Delivery", value: "royalMailSpecialDelivery" },
//   { label: "TNT Healthcare", value: "tntHealthcare" },
// ];

const MAXIMUM_DATE_SPAN = 59;

const Routific = () => {
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { user } = useAuthenticator((context) => [context.user]);
  const { token, groups: userGroup } = getUserInfo(user);
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [debounceSearchText] = useDebounce(searchText, 1000);
  const [debounceSelected] = useDebounce(selected, 1000);
  const [sort, setSort] = useState({
    date: "asc",
  });
  const [isSorting, setIsSorting] = useState(true);

  const {
    isLoading,
    data: projectData,
    isError,
    error,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['projectData_routific', user],
    queryFn: async () => {
      const fromDate = moment(startDate).format('YYYY-MM-DD');
      const toDate = moment(endDate).format('YYYY-MM-DD');
      const apiName = 'Routific_API';
      const filters = {
        fromDate,
        toDate,
        email: debounceSearchText,
        clientNames: debounceSelected?.map(item => item.value)
      }
      const pagination = {
        offset: page,
        limit: 10
      }
      const path = encodeURI(
        `/getroutificprojects?filter=${JSON.stringify(filters)}&pagination=${JSON.stringify(pagination)}&sort=${JSON.stringify(sort)}`
      );
      const params = {
        headers: {
          Authorization: token,
        },
      };
      // setIsSorting(true);
      return await API.get(apiName, path, params);
    },
    onSuccess: async (res) => {
      setIsSorting(true);
    },
    enabled: !!user,
    cacheTime: 0
  });

  const {
    data: listOfClients,
  } = useQuery({
    queryKey: ['getConfigClientData', user, 'token'],
    queryFn: async () => {
      const apiName = 'Routific_API';
      const path = `/get-config-client-data?email=${user.username}&type=token`;
      const params = {
        headers: {
          Authorization: token,
        },
      };
      const result = await API.get(apiName, path, params);
      return result?.map(item => {
        return {
          label: item.name,
          value: item.name
        }
      })
    },
    enabled: !!user,
    cacheTime: 0
  });

  const updateWindowDimensions = () => {
    if (window.innerWidth > 790) return;
    // setScale(window.innerWidth / 790);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setPage(1);
      refetch();
    }
  },[startDate, endDate, sort, refetch]);

  useEffect(() => {
    refetch();
  }, [debounceSearchText, page, debounceSelected, refetch]);

  if (isLoading) return <Loading />

  if (isError && error?.response?.status === 403) {
    return (
      <div className='container d-flex flex-column'>
        <div className='d-flex align-items-center align-self-center justify-content-center text-center mt-5'>
          <h2>{error.response.data}</h2>
        </div>
      </div>
    )
  }

  const DatepickerCustomInput = forwardRef(({ value, onClick }, ref) => (
    <InputGroup size="sm" style={{ width: '270px', height: '35px' }}>
      <InputGroup.Text id="inputGroup-sizing-sm" className={clsx(
        styles['igr-custom-icon'],
      )}>
        <img src={Images.Calendar} alt="search" />
      </InputGroup.Text>
      <Form.Control
        className={clsx(
          styles['igr-calendar-input'],
        )}
        placeholder='Select date'
        value={value}
        onClick={onClick}
        ref={ref}
      />
    </InputGroup>
  ));

  // const customItemRenderer = ({
  //   checked,
  //   option,
  //   onClick,
  //   onChange,
  //   disabled,
  // }) => {
  //   return (
  //     <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
  //       <input
  //         type="checkbox"
  //         onChange={onClick}
  //         checked={checked}
  //         tabIndex={-1}
  //         disabled={disabled}
  //       />
  //       <span>{option.label}</span>
  //     </div>
  //   )
  // };

  const customValueRenderer = (vSelected, _options) => {
    if (!vSelected.length) {
      return <span style={{ color: '#BEBEBF' }}>Select option(s)</span>
    }
    if (vSelected.length === listOfClients.length) {
      return 'All clients';
    }
  };

  return (
    <div className={clsx(
      styles['routific-wrapper'],
      'm-4 row',
    )}>
      {
        userGroup?.indexOf('Admin' || 'admin') > -1 ? (
          <>
            <div className='col-md-4'>
              <InputGroup size="sm">
                <InputGroup.Text id="inputGroup-sizing-sm" className={clsx(
                  styles['igr-custom-icon']
                )}>
                  <img src={Images.iconSearch} alt="search" />
                </InputGroup.Text>
                <Form.Control
                  className={clsx(
                    styles['igr-custom-input']
                  )}
                  maxLength={50}
                  placeholder='Search by client name'
                  onChange={(e) => {
                    setPage(1);
                    setIsSorting(false);
                    setSearchText(e?.target?.value);
                  } }
                  value={searchText}
                  defaultValue={searchText} />
              </InputGroup>
            </div>
            <div className='col-md-4'>
              <MultiSelect
                options={listOfClients || []}
                value={selected}
                onChange={(e) => {
                  setPage(1);
                  setIsSorting(false);
                  setSelected(e);
                }}
                labelledBy="Select"
                disableSearch={true}
                // ItemRenderer={customItemRenderer}
                valueRenderer={customValueRenderer}
                overrideStrings={{
                  "selectAll": "All clients",
                }}
              />
            </div>
          </>
        ) : null
      }
      <div className='col-md-4'>
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => {
            const [start, end] = date;
            let from = start;
            let to = end;
            if (!start && !end) {
              from = new Date();
              to = new Date();
            }
            setPage(1);
            setIsSorting(false);
            setStartDate(from);
            setEndDate(to);
          }}
          minDate={endDate ? null : new Date(startDate)}
          maxDate={startDate ? new Date(startDate).setDate(new Date(startDate).getDate() + MAXIMUM_DATE_SPAN) : null}
          selected={startDate}
          selectsRange
          customInput={<DatepickerCustomInput />}
          isClearable
          dateFormat="dd/MM/yyyy"
        />
      </div>
      <br />
      {
        isFetching && !isSorting ? 
          <div><Loading /></div> : 
          <RoutificProjects
            data={projectData?.data}
            total={projectData?.totalItems}
            hasNext={projectData?.isNextItem}
            setPage={setPage}
            page={page}
            setSort={setSort}
            sort={sort}
            setIsSorting={setIsSorting}
          />
      }
    </div>
  );
};

export default withAuthenticator(Routific, {
  hideSignUp: true
});
