import React from 'react';
import clsx from 'clsx';
import _get from 'lodash/get';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { getDisplayValueOfStop } from '../../utils/helpers/getProjectInfo';
import { addMinutesToTime } from '../../utils/helpers/formatDateTime';

import Images from '../../assets/images';
import styles from './styles.module.scss';

const StopItem = (props) => {
  const {
    data,
    liveTracking,
    isStartingPoint,
    onClickStop,
    stopIndex,
    routeSize,
    routeName,
    disableButton = false,
  } = props;
  const isLastStop = _get(data, 'isLastStop', false);
  const isBreak = _get(data, 'break', false);
  const order = _get(data, 'order', '');
  const eta = _get(data, 'eta', '');

  const getStatus = () => {
    if (isStartingPoint) return 'Start';
    if (isLastStop) return 'End';
    if (isBreak) return 'Break';
    return _get(data, 'stop_status', '');
  };

  const { label, icon, color } = getDisplayValueOfStop(getStatus());

  const calculateArrivalTimeForFailedRoute = () => {
    if (!data?.arrival_time) return null
    return addMinutesToTime(data.arrival_time, _get(data, 'idle_time', 0))
  }
  
  const getAddress = () => {
    if (isStartingPoint || isLastStop)
      return _get(data, 'location.address', '');
    else return `${order}. ${_get(data, 'location.name', '')}`;
  };

  const getArrivalTime = () => {
    const visit = _get(data, 'visit', null);
    if (liveTracking === 'undispatched') return calculateArrivalTimeForFailedRoute();
    if (!visit) return _get(data, 'start_time', null);
    return _get(visit, 'arrival_time', null);
  };

  const getFinishTime = () => {
    const visit = _get(data, 'visit', null);
    if (!visit) return _get(data, 'finish_time', null);
    return _get(visit, 'finish_time', null);
  };

  return (
    <div
      className={clsx(styles['container'], 'd-flex')}
      style={isBreak ? { height: '50px' } : {}}
    >
      <div className={clsx(styles['left-side'], 'col-3')}>
        <p className='text-nowrap' style={{ color }}>
          {label}
        </p>
      </div>
      <div
        className={clsx(styles['right-side'], 'd-flex align-items-start col-9')}
      >
        <div
          className={clsx(
            styles['status-icon'],
            'd-flex h-100 flex-column align-items-center'
          )}
        >
          <p>
            <img src={icon} alt='Icon' />
          </p>
          {routeSize - 1 !== stopIndex && (
            <div className={styles['status-line']}></div>
          )}
        </div>
        <div className='d-flex flex-column px-2 w-100'>
          {!isBreak && (
            <div className='d-flex mb-2'>
              <p>
                <img
                  src={Images.iconMarker}
                  alt='Icon'
                  style={{ width: 13, height: 13 }}
                />
              </p>
              <p className={clsx(styles['text'], 'ps-2')}>{getAddress()}</p>
            </div>
          )}
          <div
            className='d-flex align-items-center'
            style={{ paddingBottom: 24 }}
          >
            <img
              src={Images.iconClock}
              alt='Icon'
              style={{ width: 13, height: 13 }}
            />
            {/* Check has estimate arrival time */}
            {eta ? (
              <div className='d-flex align-items-center'>
                <p className={clsx(styles['text'], 'px-2')}>{eta}</p>
                <img
                  src={Images.iconEtaTruck}
                  alt='Icon'
                  style={{ width: 13, height: 13 }}
                />
              </div>
            ) : (
              <p className={clsx(styles['text'], 'ps-2')}>
                {getArrivalTime()}
                {!isStartingPoint && !isLastStop && ` - ${getFinishTime()}`}
              </p>
            )}
          </div>
        </div>
        <Button
          variant='light'
          className={`${
            isBreak ? 'd-none' : 'd-flex'
          } align-items-center border-0 p-0`}
          // If stop is not break point then can click
          onClick={() =>
            !isBreak && onClickStop(stopIndex, routeName)
          }
          disabled={disableButton}
        >
          <p>
            <img
              src={
                disableButton
                  ? Images.iconArrowRightCircleDisable
                  : Images.iconArrowRightCircle
              }
              alt='Detail'
              className={styles['detail-icon']}
            />
          </p>
        </Button>
      </div>
    </div>
  );
};

StopItem.propsType = {
  data: PropTypes.object,
  onClickStop: PropTypes.func,
  isStartingPoint: PropTypes.bool,
  disableButton: PropTypes.bool,
  routeIndex: PropTypes.number,
  stopIndex: PropTypes.number,
  routeSize: PropTypes.number,
  order: PropTypes.number,
  routeName: PropTypes.string,
};

export default StopItem;
