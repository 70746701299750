import React, { useRef } from 'react';
import clsx from 'clsx';
import _get from 'lodash/get';

import SwitchButton from '../../../components/switchButton';
import CheckboxButton from '../../../components/CheckboxButton';
import useOnClickOutside from '../../../utils/helpers/clickOutside';
import { LIVE_TRACKING_FIELD } from '../../../utils/constants/common';

import styles from './styles.module.scss';

const Filter = (props) => {
  const {
    sortByDepartTime,
    setSortByDepartTime = () => {},
    setShowAllRoutes = () => {},
    setShowFilter = () => {},
    routes,
    routeListNameHidden,
    showUpcomingStops,
    projectInfo,
    onHideSingleRoute,
    onShowUpcomingStops,
    id
  } = props;
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef();
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setShowFilter(false));

  const getStyle = () => (id === 1 ? { bottom: '5px' } : { top: '62px' });

  return (
    <div
      className={clsx(styles['container'], 'position-absolute p-3')}
      ref={ref}
      style={getStyle()}
    >
      <p className={styles['header-title']}>Filter</p>
      <div className='pt-2'>
        <SwitchButton
          label='Sort by departure time'
          handleToggle={setSortByDepartTime}
          isOn={sortByDepartTime}
        />
        <div className='pt-2'>
        <SwitchButton
          label="Show next 5 stops"
          handleToggle={onShowUpcomingStops}
          isOn={showUpcomingStops}
          id="showUpcomingStops"
          isDisabled={_get(projectInfo, "status", "") !== "In-progress"}
        />
        </div>
        <div className='pt-2'>
          <SwitchButton
            label={`Show all routes (${routes?.length || 0})`}
            handleToggle={setShowAllRoutes}
            isOn={routeListNameHidden?.length === 0}
            id='showHideAllRoutes'
          />
        </div>
        {
          routes?.length > 0 && (
            <div className={styles["checkbox-list"]}>
              {
                routes.map((route) => (
                  <div key={route._id} className={styles["checkbox-item"]}>
                    <CheckboxButton
                      label={_get(route, 'routeName', '-')}
                      handleClick={() => onHideSingleRoute(_get(route, 'routeName', ''))}
                      checked={!routeListNameHidden.includes(route?.routeName)}
                    />
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Filter;
