import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  SUMMARY_REPORT_FIELD,
  SUMMARY_REPORT_LABEL,
} from '../../utils/constants/Label';
import { SUMMARY_REPORT_ITEM_INFO } from '../../utils/constants/common';
import _get from 'lodash/get';
import SingleStackedChart from '../SingleStackedChart';
import styles from './styles.module.scss';

const FIELD_DISPLAY = [
  SUMMARY_REPORT_FIELD.scheduled,
  SUMMARY_REPORT_FIELD.onTime,
  SUMMARY_REPORT_FIELD.early,
  SUMMARY_REPORT_FIELD.late,
  SUMMARY_REPORT_FIELD.missed,
];

const INDICATOR_DISPLAY = [
  SUMMARY_REPORT_FIELD.totalStops,
  SUMMARY_REPORT_FIELD.workingMin,
  SUMMARY_REPORT_FIELD.totalMiles,
];

const Indicator = ({ label, value }) => (
  <p>
    {value?.toLocaleString()} <span>{label}</span>
  </p>
);

const SummaryReport = (props) => {
  const { data } = props;
  const totalMiles = _get(data, 'total_miles', 0);

  return (
    <div className={styles['report-part']}>
      <p className={clsx(styles['section-title'], 'px-3')}>
        Summary Report
      </p>
      <div className={styles['report-part__content']}>
        <>
          {data &&
            FIELD_DISPLAY.map((item) => (
              <SingleStackedChart
                key={item}
                label={SUMMARY_REPORT_LABEL[item]}
                value={_get(data, item, '')}
                percentage={_get(
                  data,
                  SUMMARY_REPORT_ITEM_INFO[item]?.percentageField,
                  0
                )}
                colorInfo={SUMMARY_REPORT_ITEM_INFO[item]}
              />
            ))}
        </>
        <div className='mt-4'>
          <div className={clsx(styles['progress-bar-overall'], 'progress')}>
            {data &&
              FIELD_DISPLAY.map((item) => {
                const percentage = _get(
                  data,
                  SUMMARY_REPORT_ITEM_INFO[item]?.percentageField,
                  0
                );
                return (
                  <div
                    key={item}
                    className='progress-bar'
                    role='progressbar'
                    aria-valuemin='0'
                    aria-valuemax='100'
                    title={`${SUMMARY_REPORT_LABEL[item]}: ${percentage}%`}
                    style={{
                      width: `${percentage}%`,
                      background: SUMMARY_REPORT_ITEM_INFO[item]?.mainColor,
                    }}
                  ></div>
                );
              })}
          </div>
        </div>
        <div
          className={clsx(
            styles['overall-indicator'],
            'd-flex mt-4 justify-content-evenly'
          )}
        >
          {INDICATOR_DISPLAY.map((item) => (
            <Indicator
              key={item}
              label={SUMMARY_REPORT_LABEL[item]}
              value={
                item === SUMMARY_REPORT_FIELD.totalMiles
                  ? totalMiles?.toFixed(1)
                  : _get(data, item, 0)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

SummaryReport.propType = {
  title: PropTypes.string,
  onHide: PropTypes.func,
  data: PropTypes.any,
  date: PropTypes.date,
};

export default SummaryReport;
