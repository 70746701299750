import React from "react";
import classNames from "classnames";
import { Container } from "react-bootstrap";
import HeaderNavBar from "./Header";

const Content = ({ isOpen, toggle, children, selectedRoute, hideHeader }) => {
  return (
    <Container
      fluid
      className={classNames("content position-relative", { "is-open": isOpen })}
      style={{ background: '#f9f9f9' }}
    >
      {
        !hideHeader ? (
          <HeaderNavBar selectedRoute={selectedRoute} toggle={toggle} isOpen={isOpen} />
        ) : null
      }
      <main>
        {children}
      </main>
    </Container>
  );
}

export default Content;