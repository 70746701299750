import _get from "lodash/get";
import { calculatePosition } from "../timeMilestones";
import Images from "../../../../../assets/images";
import { SolidBoxIcon } from "../../../../../assets/images/icons/CustomizedIcons";
import styles from "./styles.module.scss";

const VanMarker = ({ vanGroupMarker }) => {
  const { groupKey, vanMarkers } = vanGroupMarker;
  const vanMarkerData = vanMarkers[0];

  return (
    <div
      style={calculatePosition(vanMarkerData, groupKey)}
      className={`${styles["van-marker"]} position-absolute`}
    >
      <div
        style={{
          backgroundColor: `${_get(vanMarkerData, "color", "#a59e9e")}`,
        }}
        className={`${styles["van-marker__dot"]} position-absolute`}
      ></div>
      <span className={`${styles["van-marker__box"]}`}>
        {<SolidBoxIcon color={`${_get(vanMarkerData, "color", "#a59e9e")}`} />}
      </span>
      <div
        style={{
          backgroundColor: `${_get(vanMarkerData, "color", "#a59e9e")}`,
        }}
        className={`${styles["van-marker-tag"]} position-absolute`}
      >
        <span
          className={`${styles["van-marker-tag__title"]} ${styles["van-marker-tag__title--short"]}`}
        >
          {_get(vanMarkerData, "deliveredBy", "N/A").slice(0, 3).toUpperCase()}
        </span>
        <span
          className={`${styles["van-marker-tag__title"]} ${styles["van-marker-tag__title--full"]}`}
        >
          {_get(vanMarkerData, "deliveredBy", "N/A")} -{" "}
          {_get(vanMarkerData, "eta", "N/A")}
        </span>
        {!vanMarkerData?.isCompleted && (
          <img
            src={Images.whiteTruckFast}
            alt="Estimated Delivery Time"
            className={`ms-2 ${styles["van-marker-tag__truck"]}`}
            title="Estimated Delivery Time"
          />
        )}
      </div>
    </div>
  );
};

export default VanMarker;
