export const getUserInfo = (user) => {
  if (!user)
    return {
      groups: '',
      token: '',
    };
  return {
    groups: (
      user.signInUserSession.idToken.payload['cognito:groups'] || []
    ).join(','),
    token: user.signInUserSession.idToken.jwtToken,
  };
};
