import React from 'react';
import PropTypes from 'prop-types';
import Images from '../../assets/images';
import styles from './styles.module.scss';
import clsx from 'clsx';

const NoResult = (props) => {
  const { text, large } = props;
  return (
    <div
      className={clsx(
        styles['container'],
        'd-flex flex-column mt-4 w-100 align-items-center',
        {
          [styles['large']]: large
        }
      )}
    >
      <img src={Images.emptyBox} alt='No result' />
      <p className='mt-3'>{text || 'No result found'}</p>
    </div>
  );
};

NoResult.propType = {
  label: PropTypes.string,
  value: PropTypes.number,
  percentage: PropTypes.number,
  colorInfo: PropTypes.object,
};

export default NoResult;
