import iconMarkerCompleted from './marker/completed.svg';
import iconMarkerLate from './marker/late.svg';
import iconMarkerNext from './marker/next.svg';
import iconMarkerSchedule from './marker/schedule.svg';
import iconMarkerStart from './marker/start.svg';
import iconMarkerDestination from './marker/destination.svg';
import iconMarkerOnTime from './marker/onTime.svg';
import iconCurrentPosition from './marker/current.svg';
import iconZoomIn from './zoomIn.svg';
import iconZoomOut from './zoomOut.svg';
import iconCollapse from './collapse.svg';
import iconExpand from './expand.svg';
import iconFilter from './filter.svg';
import iconReport from './report.svg';
import iconDashboard from './dashboard.svg';
import iconBack from './back.svg';
import iconInfo from './info.svg';
import iconEyeOpen from './eyeOpen.svg';
import iconEyeClose from './eyeClose.svg';
import iconMarker from './marker.svg';
import iconArrowRightCircle from './arrowRightCircle.svg';
import iconArrowDown from './arrowDown.svg';
import iconClock from './clock.svg';
import iconEarly from './marker/early.svg';
import iconMissed from './marker/missed.svg';
import iconClose from './modal/close.svg';
import iconChart from './chart.svg';
import iconArrowRight from './arrowRight.svg';
import iconBreakLunch from './coffee.svg';
import iconSearch from './search.svg';
import iconCloseCircle from './closeCircle.svg';
import iconCloseSquare from './closeSquare.svg';
import iconBackCircle from './backCircle.svg';
import iconCheckMarkCircle from './checkMarkCircle.svg';
import imgAttachment from './attachment.svg';
import iconCloseCircleOutline from './closeCircleOutline.svg';
import iconFlag from './flag.svg';
import iconHome from './home.svg';
import iconEtaTruck from './truckFast.svg';
import iconExpandDisable from './expandDisabled.svg';
import iconCollapseDisable from './collapseDisabled.svg';
import iconArrowRightCircleDisable from './arrowRightCircleDisabled.svg';
import iconCrossAid from './crossAid.svg';
import emptyBox from './emptyBox.svg'
import orangeClock from './orangeClock.svg'
import box from './box.svg'
import whiteTruckFast from './whiteTruckFast.svg'
import thinBox from './thinBox.svg'
import SideBarLogo from './icons/sidebar/logo.svg';
import SideBarTransport from './icons/sidebar/transport.svg';
import SideBarDashboard from './icons/sidebar/dashboard.svg';
import SideBarDashboardItem from './icons/sidebar/dashboard-item.svg';
import SideBarArrowLeft from './icons/sidebar/arrow-collapsed.svg';
import SideBarArrowRight from './icons/sidebar/arrow-expanded.svg';
import SideBarSignout from './icons/sidebar/logout.svg';
import SortArrowDown from './icons/arrow-down.svg';
import NextPage from './icons/next-page.svg';
import PreviousPage from './icons/previous-page.svg';
import Calendar from './icons/calendar.svg';
import NoData from './icons/no-project.svg';
import Hamburger from './icons/sidebar/hamburger.svg';

const Images = {
  iconMarkerCompleted,
  iconMarkerLate,
  iconMarkerNext,
  iconMarkerSchedule,
  iconMarkerStart,
  iconMarkerDestination,
  iconMarkerOnTime,
  iconCurrentPosition,
  iconZoomIn,
  iconZoomOut,
  iconCollapse,
  iconExpand,
  iconFilter,
  iconReport,
  iconDashboard,
  iconBack,
  iconInfo,
  iconEyeOpen,
  iconEyeClose,
  iconMarker,
  iconArrowRightCircle,
  iconClock,
  iconEarly,
  iconMissed,
  iconClose,
  iconChart,
  iconArrowDown,
  iconArrowRight,
  iconBreakLunch,
  iconSearch,
  iconCloseCircle,
  iconCloseSquare,
  iconBackCircle,
  iconCheckMarkCircle,
  imgAttachment,
  iconCloseCircleOutline,
  iconFlag,
  iconHome,
  iconEtaTruck,
  iconExpandDisable,
  iconCollapseDisable,
  iconArrowRightCircleDisable,
  iconCrossAid,
  emptyBox,
  orangeClock,
  box,
  whiteTruckFast,
  thinBox,
  SideBarLogo,
  SideBarDashboard,
  SideBarDashboardItem,
  SideBarTransport,
  SideBarArrowLeft,
  SideBarArrowRight,
  SideBarSignout,
  SortArrowDown,
  NextPage,
  PreviousPage,
  Calendar,
  NoData,
  Hamburger
};

export default Images;
