import { Modal, Button } from 'react-bootstrap';
import clsx from 'clsx';
import React from 'react';
import Images from '../../../assets/images';
import PropTypes from 'prop-types';
import SummaryReport from '../../SummaryReport';
import ReportDetails from '../../ReportDetails';
import Loading from '../../Loading';
import styles from './styles.module.scss';

const SummaryReportModal = (props) => {
  const { onHide, data, name = '', date, loading } = props;
  const projectName = ` ${name} - ${date}`;

  return (
    <Modal
      {...props}
      dialogClassName='me-0 mt-0 mb-0 h-100 w-50'
      scrollable
      contentClassName={clsx(styles['modal-content'], 'rounded-0')}
      className={styles['modal']}
      size='xl'
    >
      <Modal.Header
        className={clsx(
          styles['modal-header'],
          'rounded-0 text-white fw-bold fst-italic'
        )}
      >
        <div className='d-flex align-items-center'>
          <img src={Images.iconChart} alt='Chart' />
          <p
            className={clsx(styles['modal-header__title'], 'ms-2')}
            title={projectName}
          >
            Report summary for
            <span>{projectName}</span>
          </p>
        </div>
        <Button
          className='d-flex align-items-center border-0 bg-transparent p-0'
          onClick={onHide}
        >
          <img src={Images.iconClose} alt='Close' />
        </Button>
      </Modal.Header>
      <Modal.Body className={clsx(styles['modal-body'], 'p-0')}>
        {loading === 'true' ? (
          <Loading />
        ) : (
          <>
            <SummaryReport data={data} />
            <ReportDetails data={data} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

SummaryReportModal.propType = {
  name: PropTypes.string,
  onHide: PropTypes.func,
  data: PropTypes.any,
  date: PropTypes.date,
  loading: PropTypes.string,
};

export default SummaryReportModal;
